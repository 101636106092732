import { graphql } from '../../../../generated/gql';

export const createLabelsForEntityMutation = graphql(/* GraphQL */ `
  mutation createLabelsForEntity(
    $entityId: ID!
    $entityType: LabelledEntityType!
    $labelNames: [String!]!
    $externalBoardId: ID
  ) {
    createLabelsForEntity(
      entityId: $entityId
      entityType: $entityType
      externalBoardId: $externalBoardId
      labelNames: $labelNames
    ) {
      id
      name
    }
  }
`);
