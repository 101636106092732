import { graphql } from '../../../../generated';

export const customFieldsListQuery = graphql(/* GraphQL */ `
  query customFields($externalBoardId: String) {
    customFields(externalBoardId: $externalBoardId) {
      id
      name
      createdByName
      createdAt
    }
  }
`);
