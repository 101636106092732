export const DOMAIN = PUBLIC_SITE_DOMAIN;

const ROUTES = {
  ABOUT: '/company/about',
  FINANCE: '/services/finance',
  CAREERS: '/careers',
  PRESS: '/company/press',
  PRIVACY_POLICY: '/legal/privacy-policy',
  TERMS: '/legal/terms-and-conditions',
  FAQS: '/freight-faqs',
  FAQS_FINANCE: '/support/faqs/finance',
};

export const redirectToPublicSite = Object.values(ROUTES).reduce((acc, cur) => {
  if (cur !== '/') {
    return [...acc, cur];
  }
  return acc;
}, []);
