import { graphql } from '../../../../generated/gql';

export const creditUsageGql = graphql(/* GraphQL */ `
  query creditUsage {
    creditUsage {
      creditUsage {
        mode
        remainder
        total
        used
      }
    }
  }
`);
