import { getLocationHash } from '@utils/url/getLocationHash';

export function getHashParamByName(paramName: string) {
  // considering the hash fragment is used following structure -> #paramA=valueA,paramB=valueB,paramC=valueC
  let locationHash = getLocationHash();
  let paramValue = '';
  if (locationHash) {
    locationHash = locationHash.replace('#', '');
    const commaSeparatedHash = locationHash.split(',');
    commaSeparatedHash.forEach((hashParameter) => {
      const [hashParamName, hashParamValue] = hashParameter.split('=');
      if (hashParamName === paramName) {
        paramValue = hashParamValue;
      }
    });
  }

  return paramValue;
}
