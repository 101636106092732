import { FC, ReactNode } from 'react';
import { Box, Text, Breadcrumb } from '@beacon-devops/components';
import { SpaceProps } from 'styled-system';
import { CrumbWithPermission } from '@beacon-types/crumbWithPermission';
import { useCrumbsFilteredByPermissions } from './hooks/useCrumbsFilteredByPermissions';
import { PageHeaderWrapper } from './styles';

export interface PageHeaderProps extends SpaceProps {
  title?: string;
  subTitle?: string;
  rightSideContent?: ReactNode;
  centerContent?: ReactNode;
  crumbs?: CrumbWithPermission[];
  showCrumbs?: boolean;
  disableCrumbs?: boolean;
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  subTitle,
  rightSideContent,
  centerContent,
  crumbs,
  showCrumbs = true,
  disableCrumbs,
  ...props
}) => {
  const filteredCrumbs = useCrumbsFilteredByPermissions(crumbs);
  const renderCrumbs = Boolean(showCrumbs && filteredCrumbs && filteredCrumbs.length);

  return (
    <PageHeaderWrapper {...props}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" flex={1} mr={4}>
        {renderCrumbs && (
          <Breadcrumb isDisabled={disableCrumbs} crumbs={filteredCrumbs} mb={2} data-testid="breadcrumbs" />
        )}
        {title && (
          <Text
            as="h3"
            fontSize={subTitle ? 5 : 4}
            color="secondaryShades.1"
            fontWeight="600"
            data-cy="page-header-title"
            mb={0}
          >
            {title}
          </Text>
        )}
        {subTitle && (
          <Text as="h4" color="secondaryShades.2" fontSize={1} width="100%" mt={1}>
            {subTitle}
          </Text>
        )}
      </Box>

      {centerContent && (
        <Box width={['552px', '340px', '552px', '776px']} flex={2}>
          {centerContent}
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end" height="48px" flex={1} ml={4}>
        {rightSideContent}
      </Box>
    </PageHeaderWrapper>
  );
};

export default PageHeader;
