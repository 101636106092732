import { graphql } from '../../generated/gql';

export const POLocationFragment = graphql(/* GraphQL */ `
  fragment POTransportSummaryLocation on LocationWithMilestones {
    location {
      id
      name
      facility {
        iataCode
      }
    }
    milestoneViews {
      full {
        type
        dateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
          order
        }
        otherDateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
          order
        }
        label
        order
      }
    }
  }
`);

export const searchPurchaseOrdersByCustomerIdQuery = graphql(/* GraphQL */ `
  query searchPurchaseOrdersByCustomerId(
    $page: Int! = 0
    $size: Int! = 20
    $customerId: ID!
    $criteria: [SearchCriteria!]
    $sort: [SearchSortCriteria!]
    $macros: [GqlMacro!]
  ) {
    searchPurchaseOrdersByCustomerId(
      page: $page
      size: $size
      customerId: $customerId
      criteria: $criteria
      macros: $macros
      sort: $sort
    ) {
      page
      pageSize
      totalPages
      totalRecords
      pos {
        id
        customerSupplierId
        poNumber
        supplierName
        forwarderName
        cargoReadyDate
        createdOn
        isWatched
        labels {
          id
          name
        }
        documents {
          id
          associatedId
          sizeBytes
          type
          name
          createdAt
          createdBy
          createdByUserId
          sizeBytes
        }
        associatedCargos {
          createdAt
          demurrageInfo {
            daysOnQuayPoD
            daysAtTsPort
            daysOffQuayDest
          }
          id
          errors {
            type
          }
          milestoneViews {
            po {
              type
              dateInfo {
                eventDateTime {
                  date
                  timestamp
                  zone
                }
                timeframeStart {
                  date
                  timestamp
                  zone
                }
                timeframeEnd {
                  date
                  timestamp
                  zone
                }
                lastFetched
                sourceName
                order
              }
              label
              order
              isStandalonePreviousDateMilestone
              type
            }
          }
          mode
          status
          transportSummary {
            legs
            currentLeg
            haulagePairExists
            currentVehicle {
              identification {
                licensePlate
              }
            }
            prePrimaryLoad {
              ...POTransportSummaryLocation
            }
            origin {
              ...POTransportSummaryLocation
            }
            primaryLoad {
              ...POTransportSummaryLocation
            }
            transshipments {
              ...POTransportSummaryLocation
            }
            primaryDischarge {
              ...POTransportSummaryLocation
            }
            postPrimaryDischarge {
              ...POTransportSummaryLocation
            }
            destination {
              ...POTransportSummaryLocation
            }
            warehouse {
              ...POTransportSummaryLocation
            }
          }
          shipmentInfo {
            currentSplit
            totalSplits
          }
          shipmentReferences {
            type
            value
          }
          container {
            containerNumber
          }
        }
        version
      }
    }
  }
`);
