import { graphql } from '../../../generated/gql';

export const searchOrderPageQuery = graphql(/* GraphQL */ `
  query searchOrderPage($externalBoardId: ID!, $entityId: ID!, $sort: [SearchSortCriteria!], $size: Int) {
    searchOrderPage(externalBoardId: $externalBoardId, purchaseOrderId: $entityId, sort: $sort, size: $size) {
      boardPage
      orderPosition
    }
  }
`);
