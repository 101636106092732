import { graphql } from '../../../../generated/gql';

export const updateHaulageTimeMutation = graphql(/* GraphQL */ `
  mutation updateHaulageTime($haulageTimeId: ID!, $haulageHours: Int) {
    updateHaulageTime(haulageTimeId: $haulageTimeId, haulageHours: $haulageHours) {
      haulageHours
      primaryDischargeLocationId
      primaryDischargeLocationName
      warehouseAddressBookId
      warehouseAddressBookName
      id
    }
  }
`);
