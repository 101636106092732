import { UseMutationOptions } from '@tanstack/react-query';
import { ClientError } from 'graphql-request';
import { useLiveBoardMutation } from '@features/api';
import {
  UploadFreeTrackingExtraShipmentsToBoardMutation,
  UploadFreeTrackingExtraShipmentsToBoardMutationVariables,
} from '@services/LiveBoardsService/generated/graphql';
import { uploadFreeTrackingExtraShipmentsToBoardMutation } from '../graphql/updateFreeTrackingBoardMutation';

interface Args {
  options: UseMutationOptions<
    UploadFreeTrackingExtraShipmentsToBoardMutation,
    ClientError,
    UploadFreeTrackingExtraShipmentsToBoardMutationVariables
  >;
}

export function useUpdateFreeTrackingBoardApi(args?: Args) {
  const { options } = args || {};
  return useLiveBoardMutation(
    (variables, client) => client.request(uploadFreeTrackingExtraShipmentsToBoardMutation, variables),
    options,
    null,
  );
}
