import { graphql } from '../../../../generated/gql';

export const freeTrackingBoardsQuery = graphql(/* GraphQL */ `
  query freeTrackingBoards {
    freeTrackingBoards {
      boardName
      mode
      shipmentNumbers
      boardLink
      emailAddress
      boardId
      remainingShipments
    }
  }
`);
