import { graphql } from '../../../../generated/gql';

export const labelsQuery = graphql(/* GraphQL */ `
  query labels($externalBoardId: ID) {
    labels(externalBoardId: $externalBoardId) {
      id
      name
    }
  }
`);
