import { Box } from '@beacon-devops/components';
import { useBreakpoint } from 'use-breakpoint';
import { BREAKPOINTS } from '@constants/mediaQueries';
import { HubSpotSignupForm } from './HubSpotSignupForm';
import { FreeTrackingOptionalBackdropModal } from '../FreeTrackingOptionalBackdropModal';

interface HubSpotSignupFormModalProps {
  isOpen: boolean;
  title: string;
  /**
   * Because this Modal is controlled by the parent component, it needs a way to close itself.
   * This method is passed from the parent component to the modal to close it, by setting `isOpen` to false.
   */
  close: () => void;
  hasBackdrop?: boolean;

  /**
   * In current scenarios, this signup form will always be associated with a board.
   */
  boardId: string;
}

export function HubSpotSignupFormModal({ isOpen, close, title, hasBackdrop, boardId }: HubSpotSignupFormModalProps) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  return (
    <FreeTrackingOptionalBackdropModal
      title={title}
      isOpen={isOpen}
      p={5}
      width={breakpoint === 'phone' ? '100%' : '700px'}
      handleClose={close}
      hasBackdrop={hasBackdrop}
    >
      <Box mt={4}>
        <HubSpotSignupForm boardId={boardId} />
      </Box>
    </FreeTrackingOptionalBackdropModal>
  );
}
