import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { useGraphqlQueryWithToken } from './useGraphqlQueryWithToken';
import { LIVE_BOARD_ENDPOINT } from '../constants';
import { boardsGQLMiddleware } from '../graphql/middleware/boardsGQLMiddleware';

export function useLiveBoardQuery<TQueryKey extends QueryKey, TQueryFnData, TError, TData = TQueryFnData>(
  queryKey: TQueryKey,
  fetcher: (params: TQueryKey, gqlClient: GraphQLClient) => Promise<TQueryFnData>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
) {
  return useGraphqlQueryWithToken(LIVE_BOARD_ENDPOINT, queryKey, fetcher, options, boardsGQLMiddleware);
}
