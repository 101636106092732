import { Box } from '@beacon-devops/components';
import styled from 'styled-components';

export const PageHeaderWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  ${({ pb }) => (typeof pb === 'string' || typeof pb === 'undefined' ? `padding-bottom: ${pb || '24px'}` : '')};

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 8px;
  }
`;
