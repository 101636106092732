import { Box } from '@beacon-devops/components';
import styled from 'styled-components';

export const AvatarContainer = styled(Box)<{ $isRegularCursor?: boolean; $dimensionSize?: string; $fontSize?: string }>`
  .cord-avatar-container {
    height: ${({ $dimensionSize }) => $dimensionSize || '32px'};
    width: ${({ $dimensionSize }) => $dimensionSize || '32px'};
    border-radius: 50%;
    cursor: ${({ $isRegularCursor }) => ($isRegularCursor ? 'auto' : 'pointer')};
    font-family: ${({ theme }) => theme.fonts.semibold}!important;

    & .cord-font-body {
      font-size: ${({ $fontSize }) => $fontSize || '14px'};
    }
  }
`;
