import { createContext } from 'react';
import {
  GetTokenSilentlyOptions,
  IdToken,
  LogoutOptions as SPALogoutOptions,
  RedirectLoginResult,
  User,
  GetTokenSilentlyVerboseResponse,
  RedirectLoginOptions as SPARedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import { AppState } from './appState';
import { AuthState, initialAuthState } from './authState';

export interface LogoutOptions extends Omit<SPALogoutOptions, 'onRedirect'> {}

export interface RedirectLoginOptions<TAppState = AppState>
  extends Omit<SPARedirectLoginOptions<TAppState>, 'onRedirect'> {}

export interface Auth0ContextInterface<TUser extends User = User> extends AuthState<TUser> {
  getAccessTokenSilently: {
    (options: GetTokenSilentlyOptions & { detailedResponse: true }): Promise<GetTokenSilentlyVerboseResponse>;
    (options?: GetTokenSilentlyOptions): Promise<string>;
    (options: GetTokenSilentlyOptions): Promise<GetTokenSilentlyVerboseResponse | string>;
  };
  getIdTokenClaims: () => Promise<IdToken | undefined>;
  loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void>;
  logout: (options?: LogoutOptions) => Promise<void>;
  handleRedirectCallback: (url?: string) => Promise<RedirectLoginResult>;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <Auth0Provider>.');
};

export const initialContext = {
  ...initialAuthState,
  getAccessTokenSilently: stub,
  getIdTokenClaims: stub,
  loginWithRedirect: stub,
  logout: stub,
  handleRedirectCallback: stub,
};

export const Auth0Context = createContext<Auth0ContextInterface>(initialContext);

export default Auth0Context;
