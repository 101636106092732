import { ColumnConfig, FilterTooltipKeysRoad } from '../../types/tooltip';

// columns and sections configuration derived from requirements here
// https://www.figma.com/file/Qz4X7sS77hNkm6PtPCjOTZ/Live-Board?type=design&node-id=1202-128157&mode=dev
export const RoadFilterTooltipColumns: ColumnConfig[] = [
  [FilterTooltipKeysRoad.Search],
  [FilterTooltipKeysRoad.CurrentStatus],
  [FilterTooltipKeysRoad.Labels, FilterTooltipKeysRoad.Supplier],
  [FilterTooltipKeysRoad.Timeframe, FilterTooltipKeysRoad.RollingTimeframe],
  [FilterTooltipKeysRoad.PickUpLocation, FilterTooltipKeysRoad.DropOffLocation, FilterTooltipKeysRoad.Haulier],
  [FilterTooltipKeysRoad.Toggles],
];
