import { Auth0Client } from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/react';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { LoadTestingStatus } from '@features/thirdPartyTools/loadTesting/constants/loadTesting';
import { getLoadTestingUserToken } from '@features/thirdPartyTools/loadTesting/utils/getLoadTestingUserToken';

/**
 * This service is in process of getting decommissioned
 * The sessionStorageCache is duplicate of @features/auth/factory/sessionStorageCache for temporary use
 */

export const sessionStorageCache = {
  get(key: string) {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  remove(key: string) {
    sessionStorage.removeItem(key);
  },

  // Optional
  allKeys() {
    return Object.keys(sessionStorage);
  },
};

const authClient = new Auth0Client({
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: AUTH0_AUDIENCE,
  },
  cache: sessionStorageCache,
});

async function getAuth0AccessToken() {
  let token = '';

  try {
    token = await authClient.getTokenSilently();
  } catch (error) {
    token = '';

    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'Error fetching token',
      level: 'info',
      data: {
        errorString: error.toString(),
        error,
        message: error.message,
      },
    });
  }

  if (!token && LOAD_TESTING === LoadTestingStatus.Enabled) {
    token = getLoadTestingUserToken();
  }
  return token;
}

async function forceRefreshAuthToken() {
  try {
    await authClient.getTokenSilently({
      cacheMode: 'off',
    });
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'Error fetching token',
      level: 'info',
      data: {
        errorString: error.toString(),
        error,
        message: error.message,
      },
    });
  }
}

const getAccessToken = async (): Promise<string> => {
  let token;
  try {
    token = await getAuth0AccessToken();
    const tokenDecoded: JwtPayload = jwt_decode(token);
    const isTokenExpired = tokenDecoded.exp && Date.now() >= tokenDecoded.exp * 1000;

    if (isTokenExpired) {
      throw new Error('TokenExpired');
    }

    return token;
  } catch (error) {
    token = '';
  }

  return token;
};

export default {
  getAccessToken,
  forceRefreshAuthToken,
};
