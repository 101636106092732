import { SearchSlimPurchaseOrdersQuery } from '@services/LiveBoardsService/generated/graphql';
import { graphql } from '../../../../generated/gql';

export const searchSlimPurchaseOrders = graphql(/* GraphQL */ `
  query searchSlimPurchaseOrders(
    $page: Int! = 0
    $size: Int! = 100
    $sort: [SearchSortCriteria!] = []
    $externalBoardId: String!
  ) {
    searchPurchaseOrdersByExternalBoardId(page: $page, size: $size, sort: $sort, externalBoardId: $externalBoardId) {
      page
      pageSize
      totalPages
      totalRecords
      pos {
        id
        customerSupplierId
        poNumber
        supplierName
      }
    }
  }
`);

export type SearchSlimPurchaseOrdersFromQuery = SearchSlimPurchaseOrdersQuery['searchPurchaseOrdersByExternalBoardId'];
