import { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { rudderanalytics } from '@services/clickstream';

export const TrackPageViews: FC<PropsWithChildren<Record<never, never>>> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    rudderanalytics.page();
  }, [location]);

  return <>{children}</>;
};
