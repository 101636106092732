import { SearchSortCriteria, SearchSortType } from '@services/ShipmentDataGatewayService/generated/graphql';

const sortByFields: Record<string, SearchSortCriteria> = {
  departuresAsc: {
    field: 'searchSortTimings.departureTimeForSearchSort.departureForSort',
    order_by: SearchSortType.Asc,
  },
  departuresDesc: {
    field: 'searchSortTimings.departureTimeForSearchSort.departureForSort',
    order_by: SearchSortType.Desc,
  },
  warehouseArrivalAsc: {
    field: 'searchSortTimings.arrivalTimesForSearchSort.warehouseArrivalForSort',
    order_by: SearchSortType.Asc,
  },
  warehouseArrivalDesc: {
    field: 'searchSortTimings.arrivalTimesForSearchSort.warehouseArrivalForSort',
    order_by: SearchSortType.Desc,
  },
  destinationArrivalAsc: {
    field: 'searchSortTimings.arrivalTimesForSearchSort.destinationArrivalForSort',
    order_by: SearchSortType.Asc,
  },
  destinationArrivalDesc: {
    field: 'searchSortTimings.arrivalTimesForSearchSort.destinationArrivalForSort',
    order_by: SearchSortType.Desc,
  },
  arrivalsAsc: {
    field: 'searchSortTimings.arrivalTimesForSearchSort.arrivalForSort',
    order_by: SearchSortType.Asc,
  },
  arrivalsDesc: {
    field: 'searchSortTimings.arrivalTimesForSearchSort.arrivalForSort',
    order_by: SearchSortType.Desc,
  },
  pickupAsc: {
    field: 'searchSortTimings.pickupTimesForSearchSort.pickupForSort',
    order_by: SearchSortType.Asc,
  },
  pickupDesc: {
    field: 'searchSortTimings.pickupTimesForSearchSort.pickupForSort',
    order_by: SearchSortType.Desc,
  },
  daysOnQuayPoDAsc: {
    field: 'demurrageInfo.daysOnQuayPoD',
    order_by: SearchSortType.Asc,
  },
  daysOnQuayPoDDesc: {
    field: 'demurrageInfo.daysOnQuayPoD',
    order_by: SearchSortType.Desc,
  },
  daysOffQuayDestAsc: {
    field: 'demurrageInfo.daysOffQuayDest',
    order_by: SearchSortType.Asc,
  },
  daysOffQuayDestDesc: {
    field: 'demurrageInfo.daysOffQuayDest',
    order_by: SearchSortType.Desc,
  },
  createdOnDesc: {
    field: 'createdAt',
    order_by: SearchSortType.Desc,
  },
  poIdExternalBuyerAsc: {
    field: 'slimPurchaseOrders.poNumber',
    order_by: SearchSortType.Asc,
  },
  poIdExternalBuyerDesc: {
    field: 'slimPurchaseOrders.poNumber',
    order_by: SearchSortType.Desc,
  },
  containerAsc: {
    field: 'container.containerNumber',
    order_by: SearchSortType.Asc,
  },
  containerDesc: {
    field: 'container.containerNumber',
    order_by: SearchSortType.Desc,
  },
  statusAsc: {
    field: 'status',
    order_by: SearchSortType.Asc,
  },
  statusDesc: {
    field: 'status',
    order_by: SearchSortType.Desc,
  },
  vesselAsc: {
    field: 'transportSummary.currentVehicle.displayName',
    order_by: SearchSortType.Asc,
  },
  vesselDesc: {
    field: 'transportSummary.currentVehicle.displayName',
    order_by: SearchSortType.Desc,
  },
  portOfLoadingAsc: {
    field: 'transportSummary.primaryLoadLocation.name',
    order_by: SearchSortType.Asc,
  },
  portOfLoadingDesc: {
    field: 'transportSummary.primaryLoadLocation.name',
    order_by: SearchSortType.Desc,
  },
  portOfDischargeAsc: {
    field: 'transportSummary.primaryDischargeLocation.name',
    order_by: SearchSortType.Asc,
  },
  portOfDischargeDesc: {
    field: 'transportSummary.primaryDischargeLocation.name',
    order_by: SearchSortType.Desc,
  },
  carrierAsc: {
    field: 'carrier.displayName',
    order_by: SearchSortType.Asc,
  },
  carrierDesc: {
    field: 'carrier.displayName',
    order_by: SearchSortType.Desc,
  },
  supplierNameAsc: {
    field: 'slimPurchaseOrders.supplierName',
    order_by: SearchSortType.Asc,
  },
  supplierNameDesc: {
    field: 'slimPurchaseOrders.supplierName',
    order_by: SearchSortType.Desc,
  },
  daysAtTsPortAsc: {
    field: 'demurrageInfo.daysAtTsPort',
    order_by: SearchSortType.Asc,
  },
  daysAtTsPortDesc: {
    field: 'demurrageInfo.daysAtTsPort',
    order_by: SearchSortType.Desc,
  },
  cargoReadyDateDesc: {
    field: 'slimPurchaseOrders.cargoReadyDate',
    order_by: SearchSortType.Desc,
  },
  cargoReadyDateAsc: {
    field: 'slimPurchaseOrders.cargoReadyDate',
    order_by: SearchSortType.Asc,
  },
  orderCreatedOnDesc: {
    field: 'slimPurchaseOrders.createdOn',
    order_by: SearchSortType.Desc,
  },
  orderCreatedOnAsc: {
    field: 'slimPurchaseOrders.createdOn',
    order_by: SearchSortType.Asc,
  },
  orderDateDesc: {
    field: 'slimPurchaseOrders.orderDate',
    order_by: SearchSortType.Desc,
  },
  orderDateAsc: {
    field: 'slimPurchaseOrders.orderDate',
    order_by: SearchSortType.Asc,
  },
};

export const generateSort = (sort = '') => {
  return [sortByFields[sort]];
};
