import { useState } from 'react';
import { TextButton, TextButtonProps } from '@beacon-devops/components';
import { HubSpotSignupFormModal } from './HubSpotSignupFormModal';

interface Props {
  text: string;
  boardId: string;
  title?: string;
  textButtonProps?: Omit<TextButtonProps, 'children'>;
  eventEmitter?: () => void;
}

export function HubspotSignupTextButton({ text, boardId, title, textButtonProps, eventEmitter }: Props) {
  const [isSigningUp, setIsSigningUp] = useState(false);
  return (
    <>
      <TextButton
        {...textButtonProps}
        onClick={() => {
          setIsSigningUp(true);
          if (eventEmitter) {
            eventEmitter();
          }
        }}
      >
        {text}
      </TextButton>
      <HubSpotSignupFormModal
        isOpen={isSigningUp}
        hasBackdrop
        boardId={boardId}
        close={() => setIsSigningUp(false)}
        title={title || 'Unlock commenting and collaboration with a free trial'}
      />
    </>
  );
}
