import { ColumnConfig, FilterTooltipKeysOcean } from '@features/boards/types/tooltip';

// reverse map for field name -> UI label to show in column
export const OceanFilterColumnTitles = {
  cargo_search_term: 'Search',
  status: 'Current status',
  'labels.id': 'Labels',
  'slimPurchaseOrders.customerSupplierId': 'Supplier',
  filter_cargo_arrival_departure_timeframe: 'Timeframe',
  filter_cargo_arrival_departure_relative_timeframe: 'Timeframe',
  'carrier.id': 'Carrier',
  'transportSummary.currentVehicle.displayName': 'Vessel',
  'transportSummary.originLocation.id': 'Origin',
  'transportSummary.primaryLoadLocation.id': 'Port of Loading',
  'transportSummary.transshipmentLocations.id': 'Transshipment Port',
  'transportSummary.primaryDischargeLocation.id': 'Port of Discharge',
  'transportSummary.destinationLocation.id': 'Destination',
  'transportSummary.warehouseLocation.id': 'Warehouse',
  'transportSummary.legs': 'Routing',
  toggles: 'Other filters',
};

// columns and sections configuration derived from requirements here
// https://www.figma.com/file/Qz4X7sS77hNkm6PtPCjOTZ/Live-Board?type=design&node-id=1202-128157&mode=dev
export const OceanFilterTooltipColumns: ColumnConfig[] = [
  [FilterTooltipKeysOcean.Search],
  [FilterTooltipKeysOcean.CurrentStatus],
  [FilterTooltipKeysOcean.Labels, FilterTooltipKeysOcean.Supplier],
  [
    FilterTooltipKeysOcean.Timeframe,
    FilterTooltipKeysOcean.RollingTimeframe,
    FilterTooltipKeysOcean.Carrier,
    FilterTooltipKeysOcean.Vessel,
  ],
  [
    FilterTooltipKeysOcean.Origin,
    FilterTooltipKeysOcean.PortOfLoading,
    FilterTooltipKeysOcean.TransshipmentPort,
    FilterTooltipKeysOcean.PortOfDischarge,
    FilterTooltipKeysOcean.Destination,
    FilterTooltipKeysOcean.Warehouse,
  ],
  [FilterTooltipKeysOcean.Toggles, FilterTooltipKeysOcean.Routing],
];
