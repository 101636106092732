import { useContext } from 'react';
import { LiveBoardPageContext } from './LiveBoardContext';

export function useLiveBoardContext() {
  const liveBoardContextValue = useContext(LiveBoardPageContext);

  if (liveBoardContextValue === undefined) {
    throw new Error('useLiveBoardContext can only be used in a LiveBoardPageContext tree');
  }

  if (!liveBoardContextValue.boardData) {
    throw new Error('useLiveBoardContext cannot be used without a boardData value in the context');
  }

  return {
    ...liveBoardContextValue,
    boardData: liveBoardContextValue.boardData,
  };
}
