import { graphql } from '../../../../generated/gql';

export const freeTrackingBoardInfoByBoardIdQuery = graphql(/* GraphQL */ `
  query freeTrackingBoard($boardId: ID!) {
    freeTrackingBoard(boardId: $boardId) {
      boardName
      mode
      shipmentNumbers
      boardLink
      emailAddress
      boardId
      remainingShipments
    }
  }
`);
