import { ClientError } from 'graphql-request';
import { ExistingPurchaseOrderInput, Mode } from '@services/ShipmentDataGatewayService';
import { AddExtraShipmentFormValue, StartTrackingFormValues } from './types';

/**
 *
 * @description This method converts the form fields for createFreeTrackingBoard form
 * to API payload, essentially we remove any empty fields before sending to BE
 *
 * @param createBoardFormValues
 * @returns
 */
export function getCreateBoardPayload(createBoardFormValues: StartTrackingFormValues, mode: Mode) {
  return {
    shipments: createBoardFormValues.shipments
      .filter(({ shipmentIdentifier, carrierCode }) => shipmentIdentifier || carrierCode)
      .map(({ shipmentIdentifier, carrierCode }) => ({
        shipmentIdentifier,
        carrierCode,
        purchaseOrders: [],
      })),
    mode,
  };
}

/**
 *
 * @description This method converts the form fields for createFreeTrackingBoard form
 * to API payload, essentially we remove any empty fields before sending to BE
 *
 * @param createBoardFormValues
 * @returns
 */
export function getUploadExtraShipmentsToBoardPayload(
  createBoardFormValues: StartTrackingFormValues,
  mode: Mode,
  boardId: string,
  purchaseOrders?: ExistingPurchaseOrderInput[],
) {
  return {
    shipments: createBoardFormValues.shipments
      .filter(({ shipmentIdentifier, carrierCode }) => shipmentIdentifier || carrierCode)
      .map(({ shipmentIdentifier, carrierCode }) => ({
        shipmentIdentifier,
        carrierCode,
        purchaseOrders: purchaseOrders || [],
      })),
    mode,
    boardId,
  };
}

/**
 *
 * @description This method converts the form fields for createFreeTrackingBoard form
 * to API payload, essentially we remove any empty fields before sending to BE
 *
 * @param createBoardFormValues
 * @returns
 */
export function getUploadExtraShipmentsToBoardZeroPayload(
  uploadExtraShipmentBoardFormValues: AddExtraShipmentFormValue,
  mode: Mode,
  boardId: string,
) {
  return {
    shipments: [
      {
        shipmentIdentifier: uploadExtraShipmentBoardFormValues.shipment.shipmentIdentifier,
        carrierCode: uploadExtraShipmentBoardFormValues.shipment.carrierCode,
        purchaseOrders: [],
      },
    ],
    mode,
    boardId,
  };
}

/**
 * @description This method converts the error response from createFreeTrackingBoard
 * mutation to UI errors, there is an understanding that the error message is a stringified json
 */

export function translateCreateBoardGqlErrorToUiError(error: ClientError | null) {
  return error?.response.errors?.map((error) => {
    try {
      /**
       * The expected error message is a json with `message` and `shipmentNumber` keys
       */
      const jsonError = JSON.parse(error.message);
      return {
        message: jsonError.message,
        shipmentNumber: jsonError.shipmentNumber,
      };
    } catch (_) {
      return {
        message: error.message,
        shipmentNumber: '',
      };
    }
  });
}

interface TranslatedGraphQLErrors {
  message: string;
  shipmentNumber: string;
}

/**
 * @description This method converts the error response from createFreeTrackingBoard
 * mutation to UI errors, there is an understanding that the error message is a stringified json
 */
export function translateCreateBoardGqlErrorsToUiError(errors?: (ClientError | null)[]) {
  const translatedErrors: Array<TranslatedGraphQLErrors> = [];
  errors?.forEach((error) => {
    error?.response.errors?.forEach((error) => {
      try {
        /**
         * The expected error message is a json with `message` and `shipmentNumber` keys
         */
        const jsonError = JSON.parse(error.message);
        const translatedMessage: TranslatedGraphQLErrors = {
          message: jsonError.message,
          shipmentNumber: jsonError.shipmentNumber,
        };
        translatedErrors.push(translatedMessage);
      } catch (_) {
        const translatedMessage: TranslatedGraphQLErrors = {
          message: error.message,
          shipmentNumber: '',
        };
        translatedErrors.push(translatedMessage);
      }
    });
  });

  return translatedErrors;
}
