import { graphql } from '../../../generated/gql';
import { SearchCargosQuery } from '../../../generated/graphql';

export const searchCargosQuery = graphql(/* GraphQL */ `
  query searchCargos(
    $page: Int!
    $externalBoardId: String!
    $nestedSearchTerm: String
    $size: Int!
    $sort: [SearchSortCriteria!]
  ) {
    searchCargos(
      page: $page
      externalBoardId: $externalBoardId
      nestedSearchTerm: $nestedSearchTerm
      size: $size
      sort: $sort
    ) {
      page
      pageSize
      totalPages
      totalRecords
      cargos {
        id
        errors {
          type
        }
        carrierShipmentId
        status
        freshnessTimestamp
        mode
        numberOfPackages
        customFields {
          key
          value
        }
        container {
          containerNumber
        }
        demurrageInfo {
          daysOnQuayPoD
          daysAtTsPort
          daysOffQuayDest
        }
        documents {
          id
          associatedId
          sizeBytes
          type
          name
          createdAt
          createdBy
          createdByUserId
          sizeBytes
          boardId
        }
        createdAt
        createdByName
        labels {
          id
          name
        }
        carrier {
          displayName
          nmftaCode
          iataCode
        }
        shipmentInfo {
          currentSplit
          totalSplits
        }
        shipmentReferences {
          id
          type
          value
          purchaseOrderId
        }
        slimPurchaseOrders {
          poNumber
          id
          supplierName
          customerSupplierId
        }
        isWatched
        transportSummary {
          legs
          currentLeg
          haulagePairExists
          currentJourneyNumber
          currentMode
          currentVehicle {
            displayName
            identification {
              imoNumber
              licensePlate
            }
          }
          transportDistances {
            total {
              value
              unit
            }
            remaining {
              value
              unit
            }
          }
          prePrimaryLoad {
            location {
              id
              name
              facility {
                iataCode
              }
              unLocation {
                code
                countryName
              }
            }
            milestoneViews {
              summary {
                type
                label
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                }
              }
              full {
                type
                label
                order
                isStandalonePreviousDateMilestone
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                otherDateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
              }
            }
          }
          origin {
            location {
              id
              name
              facility {
                iataCode
              }
              unLocation {
                code
                countryName
              }
            }
            milestoneViews {
              summary {
                type
                label
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                }
              }
              full {
                type
                label
                order
                isStandalonePreviousDateMilestone
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                otherDateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
              }
            }
          }
          primaryLoad {
            location {
              id
              name
              facility {
                iataCode
              }
              unLocation {
                code
                countryName
              }
            }
            milestoneViews {
              summary {
                type
                label
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                }
                hasBeenAchieved
              }
              full {
                type
                label
                order
                isStandalonePreviousDateMilestone
                hasBeenAchieved
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                otherDateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
              }
            }
          }
          transshipments {
            location {
              id
              name
              facility {
                iataCode
              }
              unLocation {
                code
                countryName
              }
            }
            milestoneViews {
              summary {
                type
                label
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                }
                hasBeenAchieved
              }
            }
          }
          primaryDischarge {
            location {
              id
              name
              facility {
                iataCode
              }
              unLocation {
                code
                countryName
              }
            }
            milestoneViews {
              summary {
                type
                label
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                }
                hasBeenAchieved
              }
              full {
                type
                label
                order
                isStandalonePreviousDateMilestone
                hasBeenAchieved
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                otherDateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
              }
            }
          }
          postPrimaryDischarge {
            location {
              id
              name
              facility {
                iataCode
              }
              unLocation {
                code
                countryName
              }
            }
            milestoneViews {
              summary {
                type
                label
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                }
                hasBeenAchieved
              }
              full {
                type
                label
                order
                isStandalonePreviousDateMilestone
                hasBeenAchieved
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                otherDateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
              }
            }
          }
          destination {
            location {
              id
              name
              facility {
                iataCode
              }
              unLocation {
                code
                countryName
              }
            }
            milestoneViews {
              summary {
                type
                label
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                }
                hasBeenAchieved
              }
              full {
                type
                label
                order
                isStandalonePreviousDateMilestone
                hasBeenAchieved
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                otherDateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
              }
            }
          }
          warehouse {
            location {
              id
              name
              facility {
                iataCode
              }
              unLocation {
                code
                countryName
              }
              address {
                address1
                city
                postcode
                country
              }
            }
            milestoneViews {
              summary {
                type
                label
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                }
                hasBeenAchieved
              }
              full {
                type
                label
                order
                isStandalonePreviousDateMilestone
                hasBeenAchieved
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                otherDateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
              }
            }
          }
        }
      }
    }
  }
`);

export type BoardCargos = SearchCargosQuery['searchCargos'];
