export const CARGO_QUERY_KEY = 'cargo';
export const CARGOS_QUERY_KEY = 'cargos';
export const ORDERS_QUERY_KEY = 'orders';
export const LIVE_BOARDS_QUERY_KEY = 'live-boards';
export const BOARD_CARGO_PAGE = 'cargo-page';
export const BOARD_ORDER_PAGE = 'order-page';
export const LIVE_BOARDS_CARGOS_QUERY_KEY = [LIVE_BOARDS_QUERY_KEY, CARGOS_QUERY_KEY];
export const CUSTOMER_PERMISSIONS = 'customer-permissions';
export const PO_SINGLE_QUERY_KEY = 'purchase-order-cargo';
export const PO_SEARCH_QUERY_KEY = 'cargo-purchase-orders';
export const OWN_COMPANY_QUERY_KEY = 'own-company';
