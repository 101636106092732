/* eslint-disable react-refresh/only-export-components */
/**
 * We need to disable eslint rule for react-refresh because of the lazy imported components,
 * The eslint rule and fast-refresh does not work for anonymous function, because they cannot compare them with each other,
 * an anonymous function have a new identity every time it is declared.
 * Now the problem is lazy imports only work for default exports and cannot be named because until they
 * are loaded React does not know their name which is why we have to create this exception.
 */

import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { BoxedLoader } from '@components/loaders';
import { DOMAIN as PS_DOMAIN, redirectToPublicSite } from '@constants/publicSite';
import { Root } from '@features/app/components/Root';
import AddFreeTrackingShipmentsModal from '@features/freeTracking/components/addFreeTrackingShipments/AddFreeTrackingShipmentsModal';
import { ConditionalPermission } from '@features/user';
import ErrorPage from '@pages/public/Error';
import * as paths from '../../sitemap/paths';

const PlatformRoutes = lazy(() => import('@features/routes/components/PlatformRoutes'));
const MarketingWebsiteRoutes = lazy(() => import('@features/routes/components/MarketingWebsiteRoutes'));
const BackwardCompatibilityRoutes = lazy(() => import('@features/routes/components/BackwardCompatibilityRoutes'));
const ExternalRedirect = lazy(() => import('@components/common/ExternalRedirect'));
const ProtectedRoute = lazy(() => import('@routes/ProtectedRoute/ProtectedRoute'));
const CompanySettingsView = lazy(() => import('@features/customer/pages/CompanySettings'));
const CreditsActivityPage = lazy(() => import('@features/shipmentCredits/pages/CreditsActivityPage'));
const POLabelSettingsWrapper = lazy(() => import('@components/poLabels/POLabelSettings/POLabelSettings'));
const TrackingPreferences = lazy(() => import('@features/settings/profile/TrackingPreferences/TrackingPreferences'));
const ProfileDetails = lazy(() => import('@features/settings/profile/ProfileDetails'));
const UsersView = lazy(() => import('@features/settings/users/Users'));
const TestSentryPage = lazy(() => import('@pages/private/TestSentry'));
const PageNotFound = lazy(() => import('@pages/public/NotFound'));
const ProtectedRouteGuard = lazy(() => import('@routes/ProtectedRouteGuard/ProtectedRouteGuard'));
const SharedRoute = lazy(() => import('@routes/SharedRoute/SharedRoute'));
const CargoCardOrPOViewSelector = lazy(
  () => import('@features/cargoTracking/components/CargoCardOrPOViewSelector/CargoCardOrPOViewSelector'),
);
const CargoTableView = lazy(() => import('@features/cargoTracking/components/CargoTableView'));
const CargoContainerDetails = lazy(() => import('@pages/private/ContainerDetails/CargoContainerDetails'));
const PurchaseOrderDetails = lazy(
  () => import('@features/cargoTracking/components/PurchaseOrder/PurchaseOrderDetails'),
);
const CargoTrackingPage = lazy(() => import('@pages/private/Tracking/CargoTrackingPage'));
const UnderMaintenancePage = lazy(() => import('@pages/public/UnderMaintenance'));
const HubPage = lazy(() => import('../../features/hub/pages/Hub'));
const ReportsPage = lazy(() => import('../../features/reports/pages/ReportsPage'));
const ThoughtspotPage = lazy(() => import('../../features/reports/pages/ThoughtspotPage'));
const PlanPage = lazy(() => import('../../pages/private/Plan'));
const TemperatureRoutePlannerPage = lazy(() => import('../../pages/private/TemperatureRoutePlanner'));
const Xeneta = lazy(() => import('../../pages/private/Xeneta'));
const OceanPointToPointSchedules = lazy(() => import('../../pages/private/OceanPointSchedules/OceanPointSchedules'));
const BoardPage = lazy(() => import('../../features/boards/features/boardView/pages/BoardPage'));
const NewBoardPage = lazy(() => import('../../features/boards/features/boardView/pages/NewBoardPage'));
const AddressBook = lazy(() => import('../../features/addressBook/pages/AddressBook'));
const HaulageTimePairs = lazy(() => import('../../features/addressBook/pages/HaulageTimePairs'));
const CustomFields = lazy(() => import('../../features/customFields/pages/CustomFields'));
const Entry = lazy(() => import('@routes/EntryRoute/EntryRoute'));
const Settings = lazy(() => import('@pages/private/Settings/Settings'));
const FreeTracking = lazy(() => import('@features/freeTracking/pages/FreeTracking'));
const UnsubscribeFreeTrackingBoardPage = lazy(
  () => import('@features/freeTracking/pages/UnsubscribeFreeTrackingBoardPage'),
);

export const routesConfig: RouteObject[] = [
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Entry />,
        errorElement: <ErrorPage />,
        children: [
          {
            element: <PlatformRoutes />,
            errorElement: <ErrorPage />,
            children: [
              {
                path: paths.PATH_ROOT,
                errorElement: <ErrorPage />,
                element: (
                  <ProtectedRouteGuard>
                    <ProtectedRoute />
                  </ProtectedRouteGuard>
                ),
                children: [
                  {
                    path: paths.PATH_HUB,
                    element: <HubPage />,
                  },

                  {
                    path: '',
                    errorElement: <ErrorPage />,
                    children: [
                      {
                        path: '',
                        element: <CargoTrackingPage />,
                        handle: {
                          permissions: {
                            requiredPermissions: [ConditionalPermission.canReadShipmentTracking],
                            redirectToOnFailure: paths.PATH_PROFILE,
                          },
                        },
                        children: [
                          {
                            path: paths.PATH_TRACK_TABLE_VIEW,
                            element: <CargoTableView />,
                            handle: {
                              permissions: {
                                requiredPermissions: [ConditionalPermission.canViewTableView],
                                redirectToOnFailure: paths.PATH_TRACK_CARD_VIEW,
                              },
                            },
                          },
                          {
                            path: paths.PATH_TRACK_CARD_VIEW,
                            element: <CargoCardOrPOViewSelector />,
                          },
                        ],
                      },
                    ],
                  },

                  {
                    path: paths.PATH_TRACK_CONTAINER_DETAILS,
                    handle: {
                      permissions: {
                        requiredPermissions: [ConditionalPermission.canReadShipmentTracking],
                        redirectToOnFailure: paths.PATH_PROFILE,
                      },
                    },
                    element: <CargoContainerDetails />,
                  },

                  {
                    path: paths.PATH_TRACK_PO_DETAILS,
                    element: <PurchaseOrderDetails />,
                  },

                  {
                    path: paths.PATH_SETTINGS,
                    element: <Settings />,
                    errorElement: <ErrorPage />,
                    children: [
                      {
                        path: paths.PATH_COMPANY_LABELS,
                        element: <POLabelSettingsWrapper />,
                        handle: {
                          permissions: {
                            requiredPermissions: [ConditionalPermission.canReadLabels],
                            redirectToOnFailure: paths.PATH_SETTINGS,
                          },
                        },
                      },
                      {
                        path: paths.PATH_ADDRESS_BOOK,
                        element: <AddressBook />,
                        handle: {
                          permissions: {
                            requiredPermissions: [ConditionalPermission.canReadAddressBook],
                            redirectToOnFailure: paths.PATH_SETTINGS,
                          },
                        },
                      },
                      {
                        path: paths.PATH_HAULAGE_TIME_PAIRS,
                        element: <HaulageTimePairs />,
                        handle: {
                          permissions: {
                            requiredPermissions: [ConditionalPermission.canReadHaulageTime],
                            redirectToOnFailure: paths.PATH_SETTINGS,
                          },
                        },
                      },
                      {
                        path: paths.PATH_CUSTOM_FIELDS,
                        element: <CustomFields />,
                      },

                      // My account
                      {
                        path: paths.PATH_PROFILE,
                        element: <ProfileDetails />,
                      },
                      {
                        path: paths.PATH_PROFILE_TRACKING,
                        element: <TrackingPreferences />,
                      },

                      // Company
                      {
                        path: paths.PATH_COMPANY,
                        element: <CompanySettingsView />,
                      },
                      {
                        path: paths.PATH_COMPANY_USERS,
                        handle: {
                          permissions: {
                            requiredPermissions: [ConditionalPermission.canWriteCustomer],
                            redirectToOnFailure: paths.PATH_SETTINGS,
                          },
                        },
                        element: <UsersView />,
                      },
                      {
                        path: paths.PATH_CREDIT_ACTIVITY,
                        element: <CreditsActivityPage />,
                      },
                    ],
                  },

                  {
                    path: paths.PATH_PLAN,
                    errorElement: <ErrorPage />,
                    children: [
                      {
                        element: <PlanPage />,
                        index: true,
                      },
                      {
                        path: paths.PATH_TEMPERATURE_ROUTE_PLANNER,
                        element: <TemperatureRoutePlannerPage />,
                        handle: {
                          permissions: {
                            requiredPermissions: [ConditionalPermission.canReadHistoricalWeatherData],
                            redirectToOnFailure: paths.PATH_PLAN,
                          },
                        },
                      },
                      {
                        path: paths.PATH_XENETA,
                        element: <Xeneta />,
                        handle: {
                          permissions: {
                            requiredPermissions: [ConditionalPermission.canReadFreightRates],
                            redirectToOnFailure: paths.PATH_REPORT,
                          },
                        },
                      },
                      {
                        path: paths.PATH_OCEAN_P2P_SCHEDULES,
                        element: <OceanPointToPointSchedules />,
                        handle: {
                          permissions: {
                            requiredPermissions: [ConditionalPermission.canReadOceanPointToPointSchedules],
                            redirectToOnFailure: paths.PATH_REPORT,
                          },
                        },
                      },
                    ],
                  },
                  {
                    path: paths.PATH_REPORT_DYNAMIC,
                    element: <ReportsPage />,
                  },
                  {
                    path: paths.PATH_THOUGHTSPOT,
                    element: <ThoughtspotPage />,
                    handle: {
                      requireEnabledFeatureFlags: ['THOUGHTSPOT'],
                    },
                  },
                  {
                    path: paths.PATH_TEST_SENTRY,
                    element: <TestSentryPage />,
                  },

                  {
                    path: paths.PATH_UNDER_MAINTENANCE,
                    element: <UnderMaintenancePage />,
                  },
                  {
                    path: paths.PATH_LIVE_BOARD_NEW,
                    element: <NewBoardPage />,
                    handle: {
                      hideAppHeader: true,
                    },
                  },
                ],
              },

              {
                element: <SharedRoute />,
                children: [
                  {
                    path: paths.PATH_LIVE_BOARD,
                    element: <BoardPage />,
                    children: [
                      {
                        path: paths.PATH_LIVE_BOARD_ADD_SHIPMENT,
                        element: <AddFreeTrackingShipmentsModal />,
                      },
                    ],
                  },
                ],
              },
              {
                path: paths.PATH_FREE_TRACKING_UNSUBSCRIBE,
                element: <UnsubscribeFreeTrackingBoardPage />,
              },
            ],
          },
          {
            element: <MarketingWebsiteRoutes />,
            children: [
              {
                path: paths.PATH_FREE_TRACKING,
                element: <FreeTracking />,
              },
              {
                path: paths.PATH_FREE_TRACKING_TRIAL,
                element: <FreeTracking carriersRequireFreeTrial />,
              },
            ],
          },
          {
            element: <BackwardCompatibilityRoutes />,
            children: [
              {
                /* Supporting this route for the sake of retro compatibility.
                 * The idea behind is that having a login route causes redirect issues with Auth0
                 * so we must redirect back to the root route and let it handle for us */
                path: '/account/signin',
                element: <Navigate to={paths.PATH_ROOT} replace />,
              },
              {
                path: '/login',
                element: <Navigate to={paths.PATH_ROOT} replace />,
              },
              {
                path: '/account/signup',
                element: <Navigate to={paths.PATH_ROOT} replace />,
              },
              {
                path: paths.PATH_LOGOUT,
                element: <BoxedLoader />,
              },
              ...redirectToPublicSite.map((route) => ({
                path: route,
                element: <ExternalRedirect targetDomain={PS_DOMAIN} targetPath={route} />,
              })),
            ],
          },
          {
            path: '*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
];
