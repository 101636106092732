import { graphql } from '../../../../generated';

export const facilitiesQuery = graphql(/* GraphQL */ `
  query facilities {
    facilities {
      id
      name
      type
      facility {
        type
        iataCode
      }
      unLocation {
        code
        countryCode
        countryName
      }
      zone
    }
  }
`);
