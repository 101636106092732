import { graphql } from '../../../generated/gql';

export const cargoCountForBoardMapQuery = graphql(/* GraphQL */ `
  query cargoCountForBoardMap($page: Int!, $externalBoardId: String!, $size: Int!, $sort: [SearchSortCriteria!]) {
    searchCargosForMap(page: $page, externalBoardId: $externalBoardId, size: $size, sort: $sort) {
      page
      pageSize
      totalPages
      totalRecords
    }
  }
`);
