import { graphql } from '../../../../generated/gql';

export const availableFreeTrackingCarriersQuery = graphql(/* GraphQL */ `
  query availableFreeTrackingCarriers {
    availableFreeTrackingCarriers {
      displayName
      nmftaCode
      iataCode
    }
  }
`);
