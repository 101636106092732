import { graphql } from '../../../../generated/gql';

export const addHaulageTimeMutation = graphql(/* GraphQL */ `
  mutation addHaulageTime($haulageTime: HaulageTimeInput!) {
    addHaulageTime(haulageTime: $haulageTime) {
      haulageHours
      primaryDischargeLocationId
      primaryDischargeLocationName
      warehouseAddressBookId
      warehouseAddressBookName
      id
    }
  }
`);
