import { InputControl } from '@beacon-devops/components';
import { ControllerRenderProps, ControllerFieldState, FieldValues, FieldPath } from 'react-hook-form';

export const RequiredTextInput =
  (
    label: string,
    placeHolder: string = '',
    /**
     * @description In case the label is not present this field helps to show the error message
     * with the field name
     */
    errorFieldNameLabel: string = '',
    onChange?: (value: string) => void,
  ) =>
  <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    field,
    fieldState,
  }: {
    field: ControllerRenderProps<TFieldValues, TName>;
    fieldState: ControllerFieldState;
  }) => {
    let errorText = '';
    const hasError = fieldState.invalid;
    if (hasError) {
      const erroneousField = label || errorFieldNameLabel;
      if (fieldState.invalid && fieldState.error) {
        if (fieldState.error.type === 'required') {
          errorText = `${erroneousField} is required`;
        } else {
          errorText = fieldState.error.message || '';
        }
      }
      if (fieldState.error && fieldState.error.type === 'custom') {
        errorText = fieldState.error.message || '';
      }
    }
    return (
      <InputControl
        id={field.name}
        labelText={label}
        {...field}
        ref={null}
        hasErrorStyle={!!fieldState.invalid}
        errorText={errorText}
        placeholder={placeHolder}
        size="medium"
        aria-label={`${field.name}-input`}
        onChange={(e) => {
          field.onChange(e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
    );
  };
