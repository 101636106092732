import { graphql } from '../../../generated/gql';

export const searchCargoPageQuery = graphql(/* GraphQL */ `
  query searchCargoPage($externalBoardId: ID!, $entityId: ID!, $sort: [SearchSortCriteria!], $size: Int) {
    searchCargoPage(externalBoardId: $externalBoardId, cargoId: $entityId, sort: $sort, size: $size) {
      boardPage
      cargoPosition
    }
  }
`);
