import bicEquipmentIdentifiers from '@constants/bicEquipmentIdentifier.json';
import prefixesByCarrier from '@constants/bicPrefixes.json';

export function getCarrierCodeForContainerNumber(containerNumber: string) {
  const identifierClause = bicEquipmentIdentifiers.join(',');
  let identifiedCarrier = '';

  Object.keys(prefixesByCarrier).forEach((carrier) => {
    const prefixes = prefixesByCarrier[carrier];

    const match = RegExp(`^(${prefixes.join('|')})[${identifierClause}][0-9]{7}$`).test(containerNumber);

    if (match) {
      identifiedCarrier = carrier;
    }
  });

  return identifiedCarrier;
}
