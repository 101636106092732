import { Box, Icon, Text } from '@beacon-devops/components';
import { CompactDropdown } from '@components/common/CompactDropdown/CompactDropdown';
import { PrivacySetting } from '@features/boards/types/privacySetting';

const PrivacySettingOptions = [
  {
    label: 'Private',
    value: PrivacySetting.Private,
  },
  {
    label: 'Public',
    value: PrivacySetting.Public,
  },
];

const PrivacySettingMessages = {
  [PrivacySetting.Private]: {
    icon: 'lock',
    title: 'Private',
    info: 'Only users who are invited or who are in your organisation can access this Live Board',
  },
  [PrivacySetting.Public]: {
    icon: 'view-map',
    title: 'Public',
    info: 'Anyone with the link can access this Live Board',
  },
};

interface PrivacySelectorProps {
  defaultPrivacySetting: PrivacySetting;
  onSelect: (selectedPrivacySetting: PrivacySetting) => void;
  isDisabled?: boolean;
}

export function PrivacySelector({ defaultPrivacySetting, onSelect, isDisabled }: PrivacySelectorProps) {
  const privacySettingMessage = PrivacySettingMessages[defaultPrivacySetting];

  return (
    <>
      <Box display="flex" alignItems="center">
        <Icon name={privacySettingMessage.icon} color="secondaryShades.1" size="large" />
        <Box ml={3}>
          <CompactDropdown
            popoverId="board-privacy-selector"
            onChange={(option) => onSelect(option.value as PrivacySetting)}
            options={PrivacySettingOptions}
            defaultValue={defaultPrivacySetting}
            positionOverride={({ left, top }) => ({
              left: left + 60,
              top,
            })}
            isDisabled={isDisabled}
          />
          <Text mt={1} color="secondaryShades.2" fontSize={1}>
            {privacySettingMessage.info}
          </Text>
        </Box>
      </Box>
    </>
  );
}
