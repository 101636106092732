import { useLiveBoardQuery } from '@features/api';
import { FreeTrackingBoardsQuery } from '@services/LiveBoardsService/generated/graphql';
import { freeTrackingBoardsQuery } from '../graphql/freeTrackingBoardsQuery';

export const FREE_TRACKING_BOARDS_QUERY_KEY = 'freeTrackingBoards';

type Args<T> = {
  select?: (data: FreeTrackingBoardsQuery) => T;
};

export function useFreeTrackingBoardsApi<T>({ select }: Args<T>) {
  return useLiveBoardQuery(
    [FREE_TRACKING_BOARDS_QUERY_KEY],
    async (_, gqlClient) => gqlClient.request(freeTrackingBoardsQuery),
    { select },
  );
}
