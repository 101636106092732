import { UseMutationOptions } from '@tanstack/react-query';
import { ClientError } from 'graphql-request';
import { useLiveBoardMutation } from '@features/api';
import {
  CreateFreeTrackingBoardMutation,
  CreateFreeTrackingBoardMutationVariables,
} from '@services/LiveBoardsService/generated/graphql';
import { createFreeTrackingBoardMutation } from '../graphql/createFreeTrackingBoardMutation';

interface Args {
  options: UseMutationOptions<CreateFreeTrackingBoardMutation, ClientError, CreateFreeTrackingBoardMutationVariables>;
}

export function useCreateFreeTrackingBoardApi(args?: Args) {
  const { options } = args || {};
  return useLiveBoardMutation(
    (variables, client) => client.request(createFreeTrackingBoardMutation, variables),
    options,
    null,
  );
}
