import { graphql } from '../../../../generated/gql';

export const getFreeTrackingUserDetailsQuery = graphql(/* GraphQL */ `
  query getFreeTrackingUserDetails($boardId: String!) {
    getFreeTrackingUserDetails(boardId: $boardId) {
      id
      email
      boardId
    }
  }
`);
