import { graphql } from '../../../../generated/gql';

export const createCustomFieldMutation = graphql(/* GraphQL */ `
  mutation createCustomField($name: String!) {
    createCustomField(name: $name) {
      id
      name
    }
  }
`);
