import React from 'react';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

export const sentryConfig = {
  dsn: SENTRY_DSN,
  release: CI_PIPELINE_IID,
  environment: ENVIRONMENT,
  ignoreErrors: [
    /ChunkLoadError/,
    /Network request failed/,
    /Failed to fetch/,
    /Authentication failed/,
    /code 403/,
    /NetworkError/,
    /Load failed/,
    /Page number exceeds/,
    /Socket timeout occurred/,
    /No Cargo found for ID/,
    /Unable to preload CSS/,
    /No target window/,
    /Users with emails/,
    /have access to the board/,
    /<unknown>/,
    /error loading dynamically imported module/,
    /AbortError/,
    /The node before which the new node is to be inserted/,
    /Importing a module script failed/,
    /Non-Error promise rejection captured with value/,
    /The node to be removed is not a child of this node/,
    /QuotaExceededError/,
    /Login required/,
    /PerformanceObserverInit/,
  ],
  tracesSampleRate: 1,
  // The sample rate for replays that begin recording immediately and last the entirety of the user's session.
  replaysSessionSampleRate: 0.1,
  // The sample rate for replays that are recorded when an error happens. This type of replay will record up to
  //  a minute of events prior to the error and continue recording until the session ends.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      // Masks all texts in the website, replacing with *****
      maskAllText: false,
      // To not load images, videos, graphs
      blockAllMedia: true,
      networkDetailAllowUrls: ['https://api-web.beacon.co.uk'],
    }),
  ],
};
