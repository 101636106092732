import { graphql } from '../../../../generated/gql';

export const creditHistoryQuery = graphql(/* GraphQL */ `
  query creditHistory {
    creditHistory {
      creditHistory {
        date
        amount
        displayName
        expiresAt
        mode
      }
    }
  }
`);
