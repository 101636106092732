import * as Sentry from '@sentry/react';
import '@features/app/base.css';
import '@features/app/cord-custom.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routesConfig } from '@routes/routesConfig/routesConfig';

function AppRouterWithSentry() {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter(routesConfig);
  return <RouterProvider router={router} />;
}

export const App = Sentry.withProfiler(AppRouterWithSentry);
