import { graphql } from '../../../../generated';

export const addressBookQuery = graphql(/* GraphQL */ `
  query addressBook {
    addressBook {
      id
      name
      createdByName
      createdAt
      updatedAt
      updatedByName
      location {
        address {
          address1
          address2
          city
          country
          postcode
        }
        id
        zone
      }
    }
  }
`);
