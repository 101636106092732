import { HUBSPOT_BOARD_ID_SELECTOR, HUBSPOT_FORM_ID, HUBSPOT_PORTAL_ID, HUBSPOT_REGION } from './constants';

export function createHubspotForm(targetId: string, onFormReady: () => void, onFormSubmitted: () => void) {
  if (window.hbspt) {
    window.hbspt.forms.create({
      region: HUBSPOT_REGION,
      portalId: HUBSPOT_PORTAL_ID,
      formId: HUBSPOT_FORM_ID,
      target: `#${targetId}`,
      onFormReady,
      onFormSubmitted,
    });
  }
}

export function setBoardIdInHubspotForm(boardId: string) {
  const boardIdInputElement = document.querySelector(HUBSPOT_BOARD_ID_SELECTOR);
  if (boardIdInputElement) {
    boardIdInputElement.setAttribute('value', boardId);
  }
}
