import { getGraphqlClient } from '@features/api';
import { graphql } from '../../../generated/gql';
import { endpoint } from '../../endpoint';

const deleteNonTrackedOceanCargoMutation = graphql(/* GraphQL */ `
  mutation deleteNonTrackedOceanCargo($cargoId: ID!) {
    deleteNonTrackedOceanCargo(cargoId: $cargoId)
  }
`);

export interface DeleteNonTrackedOceanCargoMutationArgs {
  cargoId: string;
}

export const deleteNonTrackedOceanCargo = async (args: DeleteNonTrackedOceanCargoMutationArgs) => {
  const client = await getGraphqlClient(endpoint);
  const data = await client.request(deleteNonTrackedOceanCargoMutation, { cargoId: args.cargoId });

  return data.deleteNonTrackedOceanCargo;
};
