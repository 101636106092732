import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // On beacon platform data does not change frequently, unless manually updated
      // when manually updated, we use invalidateQueries to refetch, hence we can afford to have a long stale time
      // so queries do not refetch frequently unnecessarily.
      staleTime: 60 * 1000,
    },
  },
});
