import { graphql } from '../../../../generated/gql';

export const saveReferenceMutation = graphql(/* GraphQL */ `
  mutation saveReference(
    $carrierShipmentId: ID!
    $cargoId: ID
    $shipmentReferenceId: ID
    $shipmentReference: ShipmentReferenceInput!
    $externalBoardId: String
  ) {
    saveReference(
      carrierShipmentId: $carrierShipmentId
      cargoId: $cargoId
      shipmentReferenceId: $shipmentReferenceId
      shipmentReference: $shipmentReference
      externalBoardId: $externalBoardId
    ) {
      id
      type
      value
    }
  }
`);
