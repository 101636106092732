export const enum UserRoleNames {
  ACCESS_ADMIN = 'Access Admin',
  PLATFORM_USER = 'Platform User',
  TEMPERATURE_ROUTE_USER = 'Temperature Route Planner User',
  TEMPERATURE_ROUTE_TRACER_USER = 'Temperature route tracker user',
  DEMURRAGE_USER = 'Demurrage User',
  DAYS_ON_QUAY_USER = 'Days On Quay User',
  BOARD_USER = 'Board User',
}

export enum UserOnboardingStep {
  INITIAL = 'INITIAL',
  PROFILE_ADDED = 'PROFILE_ADDED',
  FINISHED = 'FINISHED',
}

export interface NewUser {
  email: string;
  roleIds: string[];
}

export enum UserFilters {
  all = 'all',
  pending = 'pending',
  active = 'active',
  deleted = 'deleted',
}
