import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import {
  Auth0Client,
  Auth0ClientOptions,
  PopupConfigOptions,
  GetTokenWithPopupOptions,
  RedirectLoginResult,
  GetTokenSilentlyOptions,
  User,
} from '@auth0/auth0-spa-js';
import Auth0Context, { Auth0ContextInterface, LogoutOptions, RedirectLoginOptions } from './Auth0Context';
import { initialAuthState } from './authState';
import { reducer } from './reducer';
import { hasAuthParams, loginError, tokenError, deprecateRedirectUri } from './utils';

/**
 * The state of the application before the user was redirected to the login page.
 */
type AppState = {
  returnTo?: string;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

interface Auth0ProviderOptions extends Auth0ClientOptions {
  children?: React.ReactNode;
  onRedirectCallback?: (appState?: AppState, user?: User) => void;
  skipRedirectCallback?: boolean;
  context?: React.Context<Auth0ContextInterface>;
}

const defaultOnRedirectCallback = (appState?: AppState): void => {
  window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
};

export const Auth0Provider = (opts: Auth0ProviderOptions): JSX.Element => {
  const {
    children,
    skipRedirectCallback,
    onRedirectCallback = defaultOnRedirectCallback,
    context = Auth0Context,
    ...clientOpts
  } = opts;
  const [client] = useState(() => new Auth0Client(clientOpts));
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const didInitialise = useRef(false);

  useEffect(() => {
    if (didInitialise.current) {
      return;
    }
    didInitialise.current = true;
    (async (): Promise<void> => {
      try {
        let user: User | undefined;
        if (hasAuthParams() && !skipRedirectCallback) {
          const { appState } = await client.handleRedirectCallback();
          user = await client.getUser();
          onRedirectCallback(appState, user);
        } else {
          await client.checkSession();
          user = await client.getUser();
        }
        dispatch({ type: 'INITIALISED', user });
      } catch (error) {
        dispatch({ type: 'ERROR', error: loginError(error) });
      }
    })();
  }, [client, onRedirectCallback, skipRedirectCallback]);

  const loginWithRedirect = useCallback(
    (opts?: RedirectLoginOptions): Promise<void> => {
      deprecateRedirectUri(opts);

      return client.loginWithRedirect(opts);
    },
    [client],
  );

  const logout = useCallback(
    async (opts: LogoutOptions = {}): Promise<void> => {
      await client.logout(opts);
      if (opts.openUrl || opts.openUrl === false) {
        dispatch({ type: 'LOGOUT' });
      }
    },
    [client],
  );

  const getAccessTokenSilently = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (opts?: GetTokenSilentlyOptions): Promise<any> => {
      let token;
      try {
        token = await client.getTokenSilently(opts);
      } catch (error) {
        throw tokenError(error);
      } finally {
        dispatch({
          type: 'GET_ACCESS_TOKEN_COMPLETE',
          user: await client.getUser(),
        });
      }
      return token;
    },
    [client],
  );

  const getAccessTokenWithPopup = useCallback(
    async (opts?: GetTokenWithPopupOptions, config?: PopupConfigOptions): Promise<string | undefined> => {
      let token;
      try {
        token = await client.getTokenWithPopup(opts, config);
      } catch (error) {
        throw tokenError(error);
      } finally {
        dispatch({
          type: 'GET_ACCESS_TOKEN_COMPLETE',
          user: await client.getUser(),
        });
      }
      return token;
    },
    [client],
  );

  const getIdTokenClaims = useCallback(() => client.getIdTokenClaims(), [client]);

  const handleRedirectCallback = useCallback(
    async (url?: string): Promise<RedirectLoginResult> => {
      try {
        return await client.handleRedirectCallback(url);
      } catch (error) {
        throw tokenError(error);
      } finally {
        dispatch({
          type: 'HANDLE_REDIRECT_COMPLETE',
          user: await client.getUser(),
        });
      }
    },
    [client],
  );

  const contextValue = useMemo<Auth0ContextInterface<User>>(() => {
    return {
      ...state,
      getAccessTokenSilently,
      getAccessTokenWithPopup,
      getIdTokenClaims,
      loginWithRedirect,
      logout,
      handleRedirectCallback,
    };
  }, [
    state,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
    handleRedirectCallback,
  ]);

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};
