import { Box, Loader } from '@beacon-devops/components';

export function BoxedLoader() {
  return (
    <Box
      display="flex"
      height="100%"
      minHeight="inherit"
      width="100%"
      alignItems="center"
      justifyContent="center"
      data-testid="boxed-loader"
    >
      <Loader />
    </Box>
  );
}
