import { getGraphqlClient } from '@features/api';
import { graphql } from '../../../generated/gql';
import { GqlMacro, PagedSearchResponse, SearchCriteria, SearchSortCriteria } from '../../../generated/graphql';
import { endpoint } from '../../endpoint';

export const searchCargosForMapQuery = graphql(/* GraphQL */ `
  query searchCargosForMap(
    $page: Int! = 0
    $size: Int! = 20
    $criteria: [SearchCriteria!]
    $macros: [GqlMacro!]
    $sort: [SearchSortCriteria!]
  ) {
    searchCargos(page: $page, size: $size, criteria: $criteria, macros: $macros, sort: $sort) {
      page
      pageSize
      totalPages
      totalRecords
      cargos {
        id
        status
        freshnessTimestamp
        container {
          containerNumber
        }
        createdAt
        carrier {
          displayName
          nmftaCode
        }
        slimPurchaseOrders {
          poNumber
          id
          supplierName
        }
        isWatched
        transportSummary {
          primaryLoad {
            location {
              name
              type
              geoLocation {
                lat
                lon
              }
              unLocation {
                code
              }
            }
          }
          primaryDischarge {
            location {
              name
              type
              geoLocation {
                lat
                lon
              }
              unLocation {
                code
              }
            }
            milestoneViews {
              full {
                type
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                }
              }
            }
          }
          currentVehicle {
            displayName
            identification {
              imoNumber
            }
          }
          legs
          currentLeg
          primaryLegs
          currentPrimaryLeg
        }
        milestoneViews {
          po {
            type
            dateInfo {
              eventDateTime {
                date
                timestamp
                zone
              }
            }
          }
        }
      }
    }
  }
`);

interface searchCargosForMapParams {
  size: number;
  sort?: SearchSortCriteria[];
  criteria: SearchCriteria[] | [];
  macros: GqlMacro[] | [];
  isCountOnly?: boolean;
}

export const searchCargosForMap = async ({
  size,
  criteria,
  macros,
}: searchCargosForMapParams): Promise<PagedSearchResponse> => {
  const client = await getGraphqlClient(endpoint);
  const data = await client.request(searchCargosForMapQuery, { page: 0, size, criteria, macros });
  return data.searchCargos as PagedSearchResponse;
};
