import { createContext, Dispatch, PropsWithChildren, SetStateAction, useMemo } from 'react';
import { trackEvent } from '@utils/common/helpers/trackEvent';
import { BoardEntityView } from './boardEntityView';
import { BoardByExternalBoardId } from '../../../selectors/useBoardByExternalBoardIdSelector';
import { BoardsSearchParameters, useQueryStringBoards } from '../hooks/useQueryStringBoards';

type Props = {
  boardData: BoardByExternalBoardId;
  loggedInAndCurrentCustomerIdIsBoardCustomerId: boolean;
  boardHasShipments: boolean;
  searchTerm?: string;
  setSearchTerm?: (p: string) => void;
  entityView: BoardEntityView;
  setEntityView: Dispatch<SetStateAction<BoardEntityView>>;
};

type LiveBoardPageContext = Props & {
  searchTerm: string;
  page: number;
  sort: string;
  setPage: (p: number) => void;
  setSort: (p: string) => void;
  setSearchTerm: (p: string) => void;
};

export const LiveBoardPageContext = createContext<LiveBoardPageContext>({
  page: 1,
  sort: 'arrivalsDesc',
  searchTerm: '',
  setSearchTerm: () => {},
  setPage: () => {},
  setSort: () => {},
  boardData: undefined,
  loggedInAndCurrentCustomerIdIsBoardCustomerId: false,
  boardHasShipments: false,
  entityView: 'shipment',
  setEntityView: () => undefined,
});

export const LiveBoardContext = ({
  children,
  boardData,
  loggedInAndCurrentCustomerIdIsBoardCustomerId,
  boardHasShipments,
  entityView,
  setEntityView,
}: PropsWithChildren<Props>) => {
  const [searchParams, setSearchParams, resetSortAndPageState] = useQueryStringBoards(entityView);

  const setStateWithEvent = (event: string, state: Partial<BoardsSearchParameters>) => {
    const newSearchState = { ...searchParams, ...state };
    trackEvent({
      element: event,
      action: 'click',
      data: newSearchState,
    });
    setSearchParams(newSearchState);
  };

  const setSort = (sort: string) => setStateWithEvent('live-boards_sort-selector', { sort, page: 1 });
  const setPage = (page: number) => setStateWithEvent('live-boards_page-selector', { page });
  const setSearchTerm = (searchTerm: string) => setStateWithEvent('live-boards_search', { searchTerm });
  const setEntityViewAndResetSearchParams = (view: BoardEntityView) => {
    resetSortAndPageState(view);
    setEntityView(view);
  };

  const contextState = useMemo(
    () => ({
      sort: searchParams.sort ?? 1,
      page: searchParams.page ?? 'arrivalsDesc',
      searchTerm: searchParams.searchTerm ?? '',
      setSort,
      setPage,
      boardData,
      loggedInAndCurrentCustomerIdIsBoardCustomerId,
      boardHasShipments,
      setSearchTerm,
      entityView,
      setEntityView: setEntityViewAndResetSearchParams,
    }),
    [
      searchParams.sort,
      searchParams.page,
      searchParams.searchTerm,
      boardData,
      loggedInAndCurrentCustomerIdIsBoardCustomerId,
      boardHasShipments,
      setSearchTerm,
      entityView,
      setEntityView,
    ],
  );

  return <LiveBoardPageContext.Provider value={contextState}>{children}</LiveBoardPageContext.Provider>;
};
