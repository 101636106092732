import { ClientError } from 'graphql-request';
import { GraphQLErrorType } from '@features/api/graphql/types';

export function isBoardsError(error: ClientError, errorType: GraphQLErrorType) {
  let isNonExistentError = false;
  if (error && error.response && error.response.errors && error.response.errors.length) {
    const { extensions, path } = error.response.errors[0];
    if (path && path[0]) {
      const queryPath: string = path[0].toString();
      isNonExistentError = extensions.errorType === errorType && queryPath === 'board';
    }
  }

  return isNonExistentError;
}
