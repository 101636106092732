import { useFreeTrackingBoardInfoByBoardIdApi } from '@services/LiveBoardsService';
import { FreeTrackingBoardQuery } from '@services/LiveBoardsService/generated/graphql';

export const DEFAULT_REMAINING_SHIPMENTS = 10;

export const DEFAULT_BOARD_INFO_VALUE = {
  remainingShipments: DEFAULT_REMAINING_SHIPMENTS,
  emailAddress: '',
};

export function transformer(data: FreeTrackingBoardQuery) {
  return {
    ...data.freeTrackingBoard,
    remainingShipments: data ? data.freeTrackingBoard.remainingShipments : DEFAULT_REMAINING_SHIPMENTS,
  };
}

export function useFreeTrackingBoardSelector(boardId: string, enabled = true) {
  return useFreeTrackingBoardInfoByBoardIdApi({
    select: transformer,
    boardId,
    enabled,
  });
}

export type FreeTrackingBoardListFromSelector = ReturnType<typeof useFreeTrackingBoardSelector>['data'];
