import { graphql } from '../../../../generated/gql';

export const saveCustomFields = graphql(/* GraphQL */ `
  mutation saveCustomFields($customFields: [CustomFieldInput!]!, $externalBoardId: String) {
    saveCustomFields(customFields: $customFields, externalBoardId: $externalBoardId) {
      id
      name
      createdAt
      createdByName
    }
  }
`);
