import { graphql } from '../../../../generated/gql';
import { CustomerSuppliersQuery } from '../../../../generated/graphql';

export const customerSuppliersQuery = graphql(/* GraphQL */ `
  query customerSuppliers($externalBoardId: String, $relationshipToCustomer: RelationshipToCustomer) {
    customerSuppliers(externalBoardId: $externalBoardId, relationshipToCustomer: $relationshipToCustomer) {
      customerId
      supplierId
      legalName
    }
  }
`);

export type CustomerSupplierFromQuery = CustomerSuppliersQuery['customerSuppliers'];
