import { graphql } from '../../../../generated';

export const timezonesQuery = graphql(/* GraphQL */ `
  query timezones {
    timezones {
      displayName
      zone
    }
  }
`);
