import { graphql } from '../../../../generated/gql';

export const updateFreeTrackingBoardDetailsMutation = graphql(/* GraphQL */ `
  mutation updateFreeTrackingBoardDetails(
    $boardId: ID!
    $boardName: String
    $companyName: String
    $authorName: String
  ) {
    updateFreeTrackingBoardDetails(
      boardId: $boardId
      boardName: $boardName
      companyName: $companyName
      authorName: $authorName
    ) {
      boardName
      customerName
      createdByName
    }
  }
`);
