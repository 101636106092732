import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';
import Analytics from 'analytics';
import { isProd } from '@utils/env';

const measurementId = `${GTAG_ID}`;
const containerId = `${GTM_ID}`;

export const analytics = Analytics({
  app: 'Beacon Platform',
  plugins: [
    googleAnalytics({
      measurementIds: measurementId ? [measurementId] : [],
      enabled: isProd,
    }),
    googleTagManager({
      containerId,
      enabled: isProd,
    }),
  ],
});
