import { EmailDigestFrequency } from '@beacon-devops/notification-service-client';

export const oceanSwitchOptions = [
  { label: 'Off', value: EmailDigestFrequency.Off },
  { label: 'Daily', value: EmailDigestFrequency.Daily },
  { label: 'Weekly', value: EmailDigestFrequency.Weekly },
];

export const oceanQuaySwitchOptions = [
  { label: 'Off', value: EmailDigestFrequency.Off },
  { label: 'Daily', value: EmailDigestFrequency.Daily },
];

export const airSwitchOptions = [
  { label: 'Off', value: EmailDigestFrequency.Off },
  { label: 'Live', value: EmailDigestFrequency.Live },
];

export const roadSwitchOptions = [
  { label: 'Off', value: EmailDigestFrequency.Off },
  { label: 'Daily', value: EmailDigestFrequency.Daily },
];
