import { Box } from '@beacon-devops/components';
import styled from 'styled-components';

export const DropDownOptionBox = styled(Box)`
  width: 288px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryShades[6]};
  }
`;
