import { graphql } from '../../../generated/gql';

export const vehicleEventsQuery = graphql(/* GraphQL */ `
  query vehicleEvents($boardId: String!) {
    vehicleEvents(externalBoardId: $boardId) {
      eventDateTime {
        timestamp
      }
      vehicle {
        identification {
          imoNumber
        }
      }
      trackingInfo {
        geoLocation {
          lat
          lon
        }
        headingDegrees
      }
    }
  }
`);
