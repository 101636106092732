import { graphql } from '../../../generated/gql';

export const searchCargosForMapQuery = graphql(/* GraphQL */ `
  query searchCargosForMap(
    $page: Int!
    $externalBoardId: String!
    $nestedSearchTerm: String
    $size: Int!
    $sort: [SearchSortCriteria!]
  ) {
    searchCargosForMap(
      page: $page
      externalBoardId: $externalBoardId
      nestedSearchTerm: $nestedSearchTerm
      size: $size
      sort: $sort
    ) {
      page
      pageSize
      totalPages
      totalRecords
      cargos {
        id
        status
        freshnessTimestamp
        container {
          containerNumber
        }
        createdAt
        carrier {
          displayName
          nmftaCode
        }
        slimPurchaseOrders {
          id
          poNumber
        }
        isWatched
        transportSummary {
          primaryLoad {
            location {
              name
              type
              geoLocation {
                lat
                lon
              }
              unLocation {
                code
              }
            }
          }
          primaryDischarge {
            location {
              name
              type
              geoLocation {
                lat
                lon
              }
              unLocation {
                code
              }
            }
            milestoneViews {
              full {
                type
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                }
              }
            }
          }
          currentVehicle {
            displayName
            identification {
              imoNumber
            }
          }
          legs
          currentLeg
        }
        milestoneViews {
          po {
            type
            dateInfo {
              eventDateTime {
                date
                timestamp
                zone
              }
            }
          }
        }
      }
    }
  }
`);
