import { PropsWithChildren, useMemo } from 'react';
import { Display, TooltipContext, initialContext } from './TooltipContext';

interface Props {
  display?: Display;
}

export function TooltipProvider({ children, display = initialContext.display }: PropsWithChildren<Props>) {
  const contextValue = useMemo(() => ({ display }), [display]);
  return <TooltipContext.Provider value={contextValue}>{children}</TooltipContext.Provider>;
}
