import { graphql } from '../../../../generated/gql';

export const expiringCreditsQuery = graphql(/* GraphQL */ `
  query expiringCredits {
    expiringCredits {
      expiringCredits {
        expiresAt
        modes {
          amount
          mode
        }
      }
    }
  }
`);
