// in the past we stored filters in the form filterName[]=A&filterName[]=B
// links in old emails will still have this format
// we need to map it into the new format which would looks like
// JSON.stringy(filterName=[A,B]) => filterName=%5B"A"%2C"B"%5D

// example locationSearch
// ?page=1&pageSize=10&query=
// &suppliers[]=bcn1.cs.customer-supplier.003fe080-0700-4eac-ac0c-9b40c8dec8bf&sort=arrivalsDesc
// &containerOrPurchaseOrderView=containers&numberOfItemsFound=10
// &demurrageValue=1

export const getLegacyFilters = (locationSearch: string) =>
  locationSearch
    .replace('?', '')
    .split('&')
    .reduce(
      (acc, cur) => {
        const [key, value] = cur.split('=');
        if (key.includes('[]')) {
          const filterName = key.replace('[]', '');
          return {
            ...acc,
            [filterName]: JSON.stringify(acc[filterName] ? [...JSON.parse(acc[filterName]), value] : [value]),
          };
        }
        return acc;
      },
      {} as Record<string, string>,
    );
