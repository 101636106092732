import { createContext, useContext } from 'react';

export type Display = 'on' | 'off';

interface TooltipContextType {
  display: Display;
}

export const initialContext: TooltipContextType = {
  display: 'on',
};

export const TooltipContext = createContext<TooltipContextType>(initialContext);

export function useTooltipContext() {
  return useContext(TooltipContext);
}
