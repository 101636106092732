import { SlimPurchaseOrderListForFreeTrackingCustomerQuery } from '@services/LiveBoardsService/generated/graphql';
import { graphql } from '../../../../generated/gql';

export const slimPurchaseOrderList = graphql(/* GraphQL */ `
  query slimPurchaseOrderListForFreeTrackingCustomer($externalBoardId: String!) {
    slimPurchaseOrderListForFreeTrackingCustomer(externalBoardId: $externalBoardId) {
      id
      poNumber
      supplierName
      customerSupplierId
    }
  }
`);

export type SlimPurchaseOrderListFromQuery =
  SlimPurchaseOrderListForFreeTrackingCustomerQuery['slimPurchaseOrderListForFreeTrackingCustomer'];
