import { getGraphqlClient } from '@features/api';
import { PoQuery } from '@services/ShipmentDataGatewayService/generated/graphql';
import { graphql } from '../../generated/gql';
import { endpoint } from '../endpoint';

export const purchaseOrderQuery = graphql(/* GraphQL */ `
  query po($id: ID!) {
    po(id: $id) {
      customerSupplierId
      id
      poNumber
      supplierName
      cargoReadyDate
      labels {
        name
        id
      }
      documents {
        id
        associatedId
        sizeBytes
        type
        name
        createdAt
        createdBy
        createdByUserId
        sizeBytes
      }
      associatedCargos {
        createdAt
        demurrageInfo {
          daysOnQuayPoD
          daysAtTsPort
          daysOffQuayDest
        }
        id
        errors {
          type
        }
        milestoneViews {
          po {
            type
            dateInfo {
              eventDateTime {
                date
                timestamp
                zone
              }
              lastFetched
              sourceName
              order
            }
            label
            order
            isStandalonePreviousDateMilestone
          }
        }
        mode
        status
        transportSummary {
          legs
          currentLeg
          haulagePairExists
          currentVehicle {
            displayName
            identification {
              imoNumber
            }
          }
          primaryLoad {
            location {
              name
              geoLocation {
                lat
                lon
              }
              unLocation {
                code
              }
              facility {
                iataCode
              }
            }
            milestoneViews {
              full {
                type
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                otherDateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                label
                order
              }
            }
          }
          primaryDischarge {
            location {
              name
              geoLocation {
                lat
                lon
              }
              unLocation {
                code
              }
              facility {
                iataCode
              }
            }
            milestoneViews {
              full {
                type
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                otherDateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  timeframeStart {
                    date
                    timestamp
                    zone
                  }
                  timeframeEnd {
                    date
                    timestamp
                    zone
                  }
                  lastFetched
                  sourceName
                  order
                }
                label
                order
              }
            }
          }
          destination {
            location {
              name
            }
          }
          warehouse {
            location {
              id
              name
            }
          }
        }
        carrier {
          nmftaCode
        }
        shipmentInfo {
          currentSplit
          totalSplits
        }
        shipmentReferences {
          type
          value
        }
        container {
          containerNumber
        }
      }
      skus {
        skuNumber
        description
        quantity
        unitPrice {
          currency
          number
        }
        totalPrice {
          currency
          number
        }
      }
      orderDate
      version
    }
  }
`);

export type PoQueryResponse = PoQuery['po'];

export type PoQuerySkus = NonNullable<PoQueryResponse>['skus'];

export type PoQueryAssociatedCargos = NonNullable<PoQueryResponse>['associatedCargos'];

interface PurchaseOrderParams {
  poId: string;
}

export const getPurchaseOrderByPoId = async (params: PurchaseOrderParams) => {
  try {
    const client = await getGraphqlClient(endpoint);
    const data = await client.request(purchaseOrderQuery, { id: params.poId });

    return data.po;
  } catch (e) {
    return undefined;
  }
};
