import { CargoStatus } from '@services/ShipmentDataGatewayService/generated/graphql';

export const filterMappingOverride = (filterList?: string): string | undefined => {
  if (!filterList) {
    return undefined;
  }

  try {
    const parsedFilterList = <string[]>JSON.parse(filterList);
    const CDSToSCVStatusMappingOverrides = {
      DISCHARGED: CargoStatus.DischargedAtDest,
    };

    return JSON.stringify(parsedFilterList.map((filter) => CDSToSCVStatusMappingOverrides[filter] ?? filter));
  } catch (e) {
    return undefined;
  }
};
