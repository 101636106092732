import { graphql } from '../../generated/gql';

export const countPurchaseOrdersByCustomerIdQuery = graphql(/* GraphQL */ `
  query countPurchaseOrdersByCustomerId(
    $page: Int! = 0
    $size: Int! = 20
    $customerId: ID!
    $criteria: [SearchCriteria!]
    $sort: [SearchSortCriteria!]
    $macros: [GqlMacro!]
  ) {
    searchPurchaseOrdersByCustomerId(
      page: $page
      size: $size
      customerId: $customerId
      criteria: $criteria
      macros: $macros
      sort: $sort
    ) {
      totalRecords
    }
  }
`);
