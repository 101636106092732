import { UserFilters } from '@beacon-types/user';

export const USER_ID = (id: string) => `bcn1.c.user.${id}`;

export const userFilterOptions = [
  {
    label: 'All',
    value: UserFilters.all,
  },
  {
    label: 'Pending',
    value: UserFilters.pending,
  },
  {
    label: 'Active',
    value: UserFilters.active,
  },
  {
    label: 'Deleted',
    value: UserFilters.deleted,
  },
];
