import { graphql } from '../../../../generated';

export const hubCargoApiQuery = graphql(/* GraphQL */ `
  query hubCargoApi($criteria: [SearchCriteria!]!, $macros: [GqlMacro!]!) {
    searchCargos(page: 0, size: 1, criteria: $criteria, macros: $macros, sort: []) {
      totalRecords
      totalOceanRecords
      totalAirRecords
      totalRoadRecords
    }
  }
`);
