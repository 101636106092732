import { Box, Modal, ModalProps } from '@beacon-devops/components';

interface Props extends ModalProps {
  hasBackdrop?: boolean;
}

export function FreeTrackingOptionalBackdropModal({ hasBackdrop, ...modalProps }: Props) {
  return (
    <Modal
      {...(!hasBackdrop
        ? {
            // eslint-disable-next-line react/no-unstable-nested-components
            overlayElement: (props, contentElement) => (
              <Box
                {...props}
                backgroundColor="transparent !important"
                alignItems="flex-start !important"
                padding="0 !important"
              >
                {contentElement}
              </Box>
            ),
            // eslint-disable-next-line react/no-unstable-nested-components
            contentElement: (props, children) => (
              <Box {...props} boxShadow="0px 20px 80px 20px rgba(28, 45, 66, 0.30)" maxHeight="inherit !important">
                {children}
              </Box>
            ),
          }
        : {})}
      {...modalProps}
    >
      {modalProps.children}
    </Modal>
  );
}
