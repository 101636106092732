import { Text, TextButton } from '@beacon-devops/components';
import { featureAnalyticsAddMoreShipmentsClick } from '@features/freeTracking/analytics/freeTrackingPageAnalytics';
import { AdditionShipmentCta, ShipmentsLeftText } from '@features/freeTracking/components/startTrackingForm/styled';
import { ModeSpecificText } from '@features/freeTracking/constants';
import { Mode } from '@services/LiveBoardsService/generated/graphql';

interface Props {
  mode: Mode;
  isReturningUser: boolean;
  remainingShipments: number;
  hasRemainingShipments: boolean;
  onAddShipment: () => void;
  addShipmentCtaLabel: string;
  showRemainingShipments?: boolean;
}

export function AddRemainingShipments({
  mode,
  isReturningUser,
  remainingShipments,
  hasRemainingShipments,
  onAddShipment,
  addShipmentCtaLabel,
  showRemainingShipments = true,
}: Props) {
  return (
    <AdditionShipmentCta>
      <TextButton
        type="button"
        onClick={() => {
          onAddShipment();
          featureAnalyticsAddMoreShipmentsClick(mode, isReturningUser);
        }}
        disabled={hasRemainingShipments}
      >
        {addShipmentCtaLabel}
      </TextButton>
      {showRemainingShipments && (
        <ShipmentsLeftText>
          <Text fontSize={2}>{`${remainingShipments} ${ModeSpecificText[mode].cargoAddMoreLabel} left`}</Text>
        </ShipmentsLeftText>
      )}
    </AdditionShipmentCta>
  );
}
