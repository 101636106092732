import { UsersInvitedToBoardQuery } from '@services/LiveBoardsService/generated/graphql';
import { graphql } from '../../../generated/gql';

export const usersInvitedToBoardQuery = graphql(/* GraphQL */ `
  query UsersInvitedToBoard($externalBoardId: String!) {
    usersInvitedToBoard(externalBoardId: $externalBoardId) {
      userId
      email
      contactDetails {
        firstName
        lastName
      }
      isActive
      isOwner
      boardAccess {
        accessMap {
          key
          value
        }
      }
    }
  }
`);

export type UsersInvitedToBoard = UsersInvitedToBoardQuery['usersInvitedToBoard'];
