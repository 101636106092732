export enum MediaQueriesThresholds {
  LARGE_DESKTOP_THRESHOLD = 'min-width: 1440px',
}

export const CARD_BREAKPOINTS = { small: 0, large: 1400 };

const DESKTOP_BREAKPOINT = 600;
export const BREAKPOINTS = { phone: 0, desktop: DESKTOP_BREAKPOINT };
export const phone = `@media screen and (max-width: ${DESKTOP_BREAKPOINT}px)`;
export const largeDesktop = `@media screen and (${MediaQueriesThresholds.LARGE_DESKTOP_THRESHOLD})`;
