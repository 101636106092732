import { graphql } from '../../../../generated/gql';

export const savePosToCargoMutation = graphql(/* GraphQL */ `
  mutation savePosToCargo(
    $cargoId: ID! = ""
    $externalBoardId: String
    $carrierShipmentId: ID!
    $pos: [PoInput!]! = []
  ) {
    savePosToCargo(
      cargoId: $cargoId
      externalBoardId: $externalBoardId
      carrierShipmentId: $carrierShipmentId
      pos: $pos
    ) {
      slimPos {
        id
        customerSupplierId
        supplierName
        poNumber
      }
      shipmentReferences {
        id
        type
        value
        name
        purchaseOrderId
        partyId
        partyName
      }
    }
  }
`);
