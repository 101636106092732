import { graphql } from '../../../../generated/gql';

export const creditHistoryByMonthQuery = graphql(/* GraphQL */ `
  query creditHistoryByMonth($precedingMonths: Int!) {
    creditHistoryByMonth(precedingMonths: $precedingMonths) {
      months {
        creditHistory {
          amount
          mode
        }
        usageMonth
      }
    }
  }
`);
