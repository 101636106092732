import { UseMutationOptions } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { useGraphqlMutationWithToken } from './useGraphqlMutationWithToken';
import { LIVE_BOARD_ENDPOINT } from '../constants';
import { ResponseMiddleWare } from '../graphql/types';

export function useLiveBoardMutation<TMutationFnData, TError, TVariables, TData = TMutationFnData>(
  mutationFn: (variables: TVariables, gqlClient: GraphQLClient) => Promise<TData>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
  middleware?: ResponseMiddleWare | null,
  delaySuccess?: number,
) {
  return useGraphqlMutationWithToken(LIVE_BOARD_ENDPOINT, mutationFn, options, middleware, delaySuccess);
}
