import { getGraphqlClient } from '@features/api';
import { graphql } from '../../generated/gql';
import { PagedPurchaseOrders, SearchCriteria } from '../../generated/graphql';
import { endpoint } from '../endpoint';

const searchPurchaseOrdersListQuery = graphql(`
  query searchPurchaseOrdersList($page: Int! = 0, $size: Int! = 20, $customerId: ID!, $criteria: [SearchCriteria!]) {
    searchPurchaseOrdersByCustomerId(
      page: $page
      size: $size
      customerId: $customerId
      criteria: $criteria
      macros: []
      sort: []
    ) {
      page
      pageSize
      totalPages
      totalRecords
      pos {
        id
        poNumber
      }
    }
  }
`);

interface SearchPOListParams {
  page: number;
  size: number;
  customerId: string;
  criteria: SearchCriteria[] | [];
}

export const searchPurchaseOrdersList = async ({
  page,
  size,
  customerId,
  criteria,
}: SearchPOListParams): Promise<PagedPurchaseOrders> => {
  const client = await getGraphqlClient(endpoint);
  const data = await client.request(searchPurchaseOrdersListQuery, {
    page: page > 0 ? page - 1 : 0,
    size,
    customerId,
    criteria,
  });

  return data.searchPurchaseOrdersByCustomerId as PagedPurchaseOrders;
};
