/*
  Permission state type for permission based conditionals that go into User Context and used across FE codebase
*/
export enum ConditionalPermission {
  canReadPO = 'canReadPO',
  canReadShipmentTracking = 'canReadShipmentTracking',
  canRequestShipmentTracking = 'canRequestShipmentTracking',
  canReadDaysOnQuay = 'canReadDaysOnQuay',
  canReadDaysOffQuay = 'canReadDaysOffQuay',
  canReadLabels = 'canReadLabels',
  canWriteLabels = 'canWriteLabels',
  canViewTrackingHome = 'canViewTrackingHome',
  canReadHistoricalWeatherData = 'canReadHistoricalWeatherData',
  canReadHistoricalShipments = 'canReadHistoricalShipments',
  canWriteToWatchlist = 'canWriteToWatchlist',
  canReadWatchlist = 'canReadWatchlist',
  canViewTableView = 'canViewTableView',
  canExportCsv = 'canExportCsv',
  canReadAddressBook = 'canReadAddressBook',
  canWriteAddressBook = 'canWriteAddressBook',
  canReadHaulageTime = 'canReadHaulageTime',
  canWriteHaulageTime = 'canWriteHaulageTime',
  canReadDocuments = 'canReadDocuments',
  canReadDocumentCount = 'canReadDocumentCount',
  canReadFreightRates = 'canReadFreightRates',
  canReadOceanPointToPointSchedules = 'canReadOceanPointToPointSchedules',
  canReadCustomFields = 'canReadCustomFields',
  canWriteCustomFields = 'canWriteCustomFields',
  canRequestOceanShipmentTrackingWithoutCarrier = 'canRequestOceanShipmentTrackingWithoutCarrier',
  canReadOceanRoutesReport = 'canReadOceanRoutesReport',
  canReadAirVolumeReport = 'canReadAirVolumeReport',
  canReadOceanVolumeReport = 'canReadOceanVolumeReport',
  canReadOceanTransitTimeReport = 'canReadOceanTransitTimeReport',
  canReadAirTransitTimeReport = 'canReadAirTransitTimeReport',
  canReadRoadVolumeReport = 'canReadRoadVolumeReport',
  canReadRoadTransitTimeReport = 'canReadRoadTransitTimeReport',
  canReadOceanEmissionsReport = 'canReadOceanEmissionsReport',
  canReadOceanVesselGeolocationData = 'canReadOceanVesselGeolocationData',
  canDeleteShipmentTracking = 'canDeleteShipmentTracking',
  canReadBoard = 'canReadBoard',
  canViewBoardsOnly = 'canViewBoardsOnly',
  canWriteCustomer = 'canWriteCustomer',
  canWriteOrderBoards = 'canWriteOrderBoards',
}

export type UserPermissions = {
  [key in ConditionalPermission]: boolean;
};
