import { NotificationId } from '@beacon-devops/notification-service-client';

export const NotificationItemMetadata = {
  [NotificationId.ScheduledRoadShipmentsAlert]: {
    name: 'Road departures',
    nameWithMode: 'Road: Departures',
    description: 'Road shipments that will depart in the next 24h - summary',
    sliderDescription: undefined,
  },
  [NotificationId.AwbDepartedAlert]: {
    name: 'AWB departure confirmation',
    nameWithMode: 'Air: AWB departure confirmation',
    description: 'AWBs that have departed from origin airport',
    sliderDescription: undefined,
  },
  [NotificationId.DemurrageContainersAlert]: {
    name: 'Days on quay',
    nameWithMode: 'Ocean: Days on quay',
    description: 'A summary of containers sitting on quay at PoD',
    sliderDescription: 'Set the threshold for notifications about the number of days that your containers are on quay',
  },
  [NotificationId.DetentionContainersAlert]: {
    name: 'Days off quay',
    nameWithMode: 'Ocean: Days off quay',
    description: 'A summary of containers off quay from PoD',
    sliderDescription: 'Set the threshold for notifications about the number of days that your containers are off quay',
  },
  [NotificationId.InTransitContainersAlert]: {
    name: 'ETA changes',
    nameWithMode: 'Ocean: ETA changes',
    description: 'A summary of containers that have an ETA change',
    sliderDescription: undefined,
  },
  [NotificationId.ArrivedContainersAlert]: {
    name: 'Arrivals',
    nameWithMode: 'Ocean: Arrivals',
    description: 'A summary of containers that have arrived at PoD',
    sliderDescription: undefined,
  },
};

export const notificationIds = Object.keys(NotificationItemMetadata) as NotificationId[];
