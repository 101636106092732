import { graphql } from '../../../../generated';

export const haulageTimePairsQuery = graphql(/* GraphQL */ `
  query haulageTimes {
    haulageTimes {
      id
      haulageHours
      primaryDischargeLocationId
      primaryDischargeLocationName
      warehouseAddressBookId
      warehouseAddressBookName
      updatedByName
      createdByName
      updatedAt
      createdAt
    }
  }
`);
