/* SEGMENTS */
export const SEGMENT_CARGO_ID = ':cargoId';
export const SEGMENT_PO_ID = ':poId';
const SEGMENT_REPORTS_ID = ':reportId';

/* GENERICS */
export const PATH_ROOT = '/';
export const PATH_LOGOUT = '/logout';
export const PATH_UNDER_MAINTENANCE = '/under-maintenance';
export const PATH_TEST_SENTRY = '/test-sentry';

/* HHOME */
export const PATH_HUB = '/hub';

/* TRACK */
export const PATH_TRACK_CARD_VIEW = '/track/card-view';
export const PATH_TRACK_TABLE_VIEW = '/track/table-view';
export const PATH_TRACK_CONTAINER_DETAILS = `/track/containers/${SEGMENT_CARGO_ID}`;
export const PATH_TRACK_PO_DETAILS = `/track/purchase-orders/${SEGMENT_PO_ID}`;

/* CONFIGURATION */
export const PATH_SETTINGS = '/settings';
export const PATH_COMPANY_LABELS = '/settings/po-labels';
export const PATH_ADDRESS_BOOK = '/settings/address-book';
export const PATH_HAULAGE_TIME_PAIRS = '/settings/haulage-time';
export const PATH_CUSTOM_FIELDS = '/settings/custom-fields';

/* PROFILE */
export const PATH_PROFILE = '/settings/profile';
export const PATH_PROFILE_TRACKING = '/settings/tracking-preferences';

/* COMPANY */
export const PATH_COMPANY = '/settings/general';
export const PATH_COMPANY_USERS = '/settings/users';
export const PATH_CREDIT_ACTIVITY = '/settings/credit-activity';

/* PLAN */
export const PATH_PLAN = '/plan';
export const PATH_TEMPERATURE_ROUTE_PLANNER = '/plan/temperature-route-planner';
export const PATH_XENETA = '/plan/xeneta';
export const PATH_OCEAN_P2P_SCHEDULES = '/plan/ocean-point-schedules';

/* REPORT */
export const PATH_REPORT = '/report';
export const PATH_REPORT_DYNAMIC = `/report/${SEGMENT_REPORTS_ID}?`;

/* THOUGHTSPOT */
export const PATH_THOUGHTSPOT = '/thoughtspot';

/* Live Boards */
export const PATH_LIVE_BOARD_PREFIX = '/live-board';
export const PATH_LIVE_BOARD = `${PATH_LIVE_BOARD_PREFIX}/:customerName/:boardName/:externalBoardId`;
export const PATH_LIVE_BOARD_ADD_SHIPMENT = 'add-shipments';
export const PATH_LIVE_BOARD_NEW = `${PATH_LIVE_BOARD_PREFIX}/new`;

/* Free Tracking */
export const PATH_FREE_TRACKING = '/beacon-free-tracking';
export const PATH_FREE_TRACKING_UNSUBSCRIBE = '/live-board/unsubscribe';
export const PATH_FREE_TRACKING_TRIAL = '/beacon-free-tracking-trial';
