import { POST_MESSAGE_TYPE_FRAME_HEIGHT, POST_MESSAGE_TYPE_ON_CREATE_BOARD } from './constants';

/**
 * Code to be written in the parent window ( beacon.com ) communicated to agency
    <script>
      window.addEventListener('message', (event) => {
        if(event.data.type === "FREE_TRACKING_BOARD_CREATED") {
          window.open(event.data.payload.boardLink, '_blank')
        }
      })
    </script>
 */
export function postBoardLinkToParentWindow(boardUrl: string, force: boolean) {
  const parentWindow = window.parent;
  if (parentWindow && boardUrl) {
    parentWindow.postMessage(
      {
        type: POST_MESSAGE_TYPE_ON_CREATE_BOARD,
        payload: {
          boardLink: boardUrl,
        },
      },
      '*',
    );
    if (force) {
      parentWindow.location = boardUrl;
    }
  }
}

/**
 * Code to be written in parent window ( beacon.com ) communicated to agency
 * 
 * window.addEventListener('message', (event) => {
    if(event.data.type === "FRAME_HEIGHT" ) {
      const height = event.data.payload.frameHeight;
      console.log(event.data.payload)
      if(height >  500) {
        const iframe = $('iframe[name="shipmentTracking"]');
        const nearestWrapper = iframe.closest('.iframe-wrapper');
        const nearestEmbed = iframe.closest('.iframe-embed');
        nearestWrapper.css('max-height', height );
        nearestWrapper.css('height', height );
        nearestEmbed.css('max-height', height );
        nearestEmbed.css('height', height );
      }
    }
  );
 * 
 */

export function postFrameHeightToParentWindow(frameHeight: number) {
  const parentWindow = window.parent;
  if (parentWindow && frameHeight) {
    parentWindow.postMessage(
      {
        type: POST_MESSAGE_TYPE_FRAME_HEIGHT,
        payload: {
          frameHeight,
        },
      },
      '*',
    );
  }
}
