import { Mode } from '@services/ShipmentDataGatewayService';

export const ModeSpecificText = {
  [Mode.Ocean]: {
    cargoLabel: 'Container',
    cargoBoardLabel: 'Container',
    cargoAddMoreLabel: 'containers',
    cargoInputPlaceHolder: 'Enter a container number',
    cargoInputPlaceHolderBoardZero: 'Container number',
    cargoExtraShipmentsPlaceHolder: 'MSKU9070345\nEISUU9070654',
    boardHeaderCountLabel: 'Containers',
  },
  [Mode.Air]: {
    cargoLabel: 'AWB Number',
    cargoBoardLabel: 'AWB',
    cargoAddMoreLabel: 'air waybills',
    cargoInputPlaceHolder: 'Enter an air waybill number',
    cargoInputPlaceHolderBoardZero: 'Air waybill number',
    cargoExtraShipmentsPlaceHolder: '235-47285943\n172-52674624',
    boardHeaderCountLabel: 'AWB Numbers',
  },
};

export const POST_MESSAGE_TYPE_ON_CREATE_BOARD = 'FREE_TRACKING_BOARD_CREATED';
export const POST_MESSAGE_TYPE_FRAME_HEIGHT = 'FRAME_HEIGHT';

export const FreeTrackingCarriersRequireFreeTrial = {
  carrierListForFreeTrial: [
    { label: 'AC Container Line', value: 'AC Container Line' },

    { label: 'AGI Global Logistics', value: 'AGI Global Logistics' },

    { label: 'Aladin Express', value: 'Aladin Express' },

    { label: 'Allalouf ', value: 'Allalouf ' },

    { label: 'Amass', value: 'Amass' },

    { label: 'Apex Shipping', value: 'Apex Shipping' },

    { label: 'Avana Global', value: 'Avana Global' },

    { label: 'PSA BDP ', value: 'PSA BDP ' },

    { label: 'Bee Logistics', value: 'Bee Logistics' },

    { label: 'Ben Line Agencies', value: 'Ben Line Agencies' },

    { label: 'BLPL Singapore', value: 'BLPL Singapore' },

    { label: 'Blue Anchor Line', value: 'Blue Anchor Line' },

    { label: 'Blue Water Lines', value: 'Blue Water Lines' },

    { label: 'Bollore Logistics', value: 'Bollore Logistics' },

    { label: 'C.H. Robinson', value: 'C.H. Robinson' },

    { label: 'CEVA Logistics', value: 'CEVA Logistics' },

    { label: 'Crowley', value: 'Crowley' },

    { label: 'Danmar Lines', value: 'Danmar Lines' },

    { label: 'DB Schenker', value: 'DB Schenker' },

    { label: 'DHL', value: 'DHL' },
    { label: 'DSV Ocean Transport', value: 'DSV Ocean Transport' },

    { label: 'ECU Line ', value: 'ECU Line ' },

    { label: 'EFL Global Shipping and Logistics Services', value: 'EFL Global Shipping and Logistics Services' },

    { label: 'Emkay Lines', value: 'Emkay Lines' },

    { label: 'Expeditors', value: 'Expeditors' },

    { label: 'Flexport', value: 'Flexport' },

    { label: 'Freightos', value: 'Freightos' },

    { label: 'GEODIS', value: 'GEODIS' },
    { label: 'JAS ', value: 'JAS ' },

    { label: 'Kerry Logistics', value: 'Kerry Logistics' },

    { label: 'King Ocean', value: 'King Ocean' },

    { label: 'Kintetsu World Express (KWE)', value: 'Kintetsu World Express (KWE)' },

    { label: 'Kuehne + Nagel', value: 'Kuehne + Nagel' },

    { label: 'Lancer Container Lines', value: 'Lancer Container Lines' },

    { label: 'Maxicon Container Line (MCL)', value: 'Maxicon Container Line (MCL)' },

    { label: 'Nippon Express', value: 'Nippon Express' },

    { label: 'Orient Star ', value: 'Orient Star ' },

    { label: 'Seaboard Marine', value: 'Seaboard Marine' },

    { label: 'Seamaster Logistics', value: 'Seamaster Logistics' },

    { label: 'SETH Shipping', value: 'SETH Shipping' },

    { label: 'Sinotrans', value: 'Sinotrans' },

    { label: 'Stolt Tank Containers', value: 'Stolt Tank Containers' },

    { label: 'Sunmarine', value: 'Sunmarine' },

    { label: 'Unifeeder', value: 'Unifeeder' },

    { label: 'Volta', value: 'Volta' },

    { label: 'XPO Logistics', value: 'XPO Logistics' },

    { label: 'Yusen Logistics', value: 'Yusen Logistics' },
  ],
};
