import { ClientError } from 'graphql-request';

export enum GraphqlErrorExtensionClassification {
  'InvalidSyntax' = 'InvalidSyntax',
  'ValidationError' = 'ValidationError',
  'DataFetchingException' = 'DataFetchingException',
  'NullValueInNonNullableField' = 'NullValueInNonNullableField',
  'OperationNotSupported' = 'OperationNotSupported',
  'ExecutionAborted' = 'ExecutionAborted',
  'PermissionDenied' = 'PermissionDenied',
}

export interface GraphQLClientResponse<Data> {
  status: number;
  headers: Headers;
  data?: Data;
  errors?: {
    message: string;
    locations?: readonly {
      line: number;
      column: number;
    }[];
    extensions: {
      classification?: GraphqlErrorExtensionClassification;
    };
  }[];
}

export enum GraphQLErrorType {
  UNKNOWN = 'UNKNOWN',
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  BAD_REQUEST = 'BAD_REQUEST',
  UNAVAILABLE = 'UNAVAILABLE',
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
}

export type ResponseMiddleWare = (response: GraphQLClientResponse<unknown> | ClientError | Error) => void;
