import { GraphQLClient } from 'graphql-request';
import AuthService from '@services/AuthService';
import { commonGraphqlResponseMiddleware } from './middleware/commonGraphqlResponseMiddleware';
import { ResponseMiddleWare } from './types';

export const getGraphqlClient = async (
  endpoint: string,
  customResponseMiddleware?: ResponseMiddleWare,
): Promise<GraphQLClient> => {
  const token = await AuthService.getAccessToken();
  const responseMiddleware = customResponseMiddleware || commonGraphqlResponseMiddleware;
  // eslint-disable-next-line
  //@ts-ignore
  return new GraphQLClient(endpoint, { headers: { authorization: `Bearer ${token}` }, responseMiddleware });
};
