import { useCurrentUserIfPresentFromContextAsync } from '@features/user/currentUser/context';
import { BoardAccessLevelEnum } from '@services/ShipmentDataGatewayService';

export const useCurrentUserAccessLevel = (boardId: string) => {
  const { boardAccess, permissions } = useCurrentUserIfPresentFromContextAsync();

  const currentUserBoardAccess = boardAccess?.[boardId] || BoardAccessLevelEnum.Viewer;

  /*
   * A platform user will always be a collaborator hence why the ternary
   */
  const accessLevel = permissions?.canViewBoardsOnly ? currentUserBoardAccess : BoardAccessLevelEnum.Collaborator;

  return {
    accessLevel,
    isViewer: accessLevel === BoardAccessLevelEnum.Viewer,
    isCommenter: accessLevel === BoardAccessLevelEnum.Commenter,
    isCollaborator: accessLevel === BoardAccessLevelEnum.Collaborator,
  };
};
