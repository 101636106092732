import { trackEvent } from '@utils/index';

/**
 * Free Tracking Page Analytics
 * https://beacon-freight.atlassian.net/wiki/spaces/DATA/pages/2077949954/Free+Tracking
 */

export function featureAnalyticsFreeTrackingPageView(
  pageMode: string,
  isReturningUser: boolean,
  existingBoards: { boardName: string; mode: string }[],
) {
  trackEvent({
    element: 'free-tracking-page',
    action: 'view',
    data: { pageMode, isReturningUser, existingBoards },
  });
}

/**
 * Start tracking form analytics
 */

export function featureAnalyticsTrackMoreCarriersClick(pageMode: string, isReturningUser: boolean) {
  trackEvent({
    element: 'track-more-carriers',
    action: 'click',
    data: { pageMode, isReturningUser },
  });
}

export function featureAnalyticsCarrierSelectAuto(
  pageMode: string,
  carrier: string,
  carrierCode: string,
  isReturningUser: boolean,
) {
  trackEvent({
    element: 'carrier-selection',
    action: 'auto',
    data: { pageMode, isReturningUser, carrierCode, carrier },
  });
}

export function featureAnalyticsCarrierSelectManual(
  pageMode: string,
  carrier: string,
  carrierCode: string,
  isReturningUser: boolean,
) {
  trackEvent({
    element: 'carrier-selection',
    action: 'manual',
    data: { pageMode, isReturningUser, carrierCode, carrier },
  });
}

export function featureAnalyticsAddMoreShipmentsClick(pageMode: string, isReturningUser: boolean) {
  trackEvent({
    element: 'add-more-shipments',
    action: 'click',
    data: { pageMode, isReturningUser },
  });
}

export function featureAnalyticsStartTrackingClick(
  pageMode: string,
  isReturningUser: boolean,
  shipments: {
    shipmentIdentifier: string;
    carrierCode?: string;
  }[],
) {
  trackEvent({
    element: 'start-tracking',
    action: 'click',
    data: { pageMode, isReturningUser, freeTrackingShipments: shipments },
  });
}

export function featureAnalyticsStartTrackingSuccess(pageMode: string) {
  trackEvent({
    element: 'start-tracking',
    action: 'success',
    data: { pageMode },
  });
}

export function featureAnalyticsAddMoreShipmentsSuccess(pageMode: string, shipmentCount: number) {
  trackEvent({
    pathOverride: 'free-tracking',
    element: 'add-more-shipments',
    action: 'success',
    data: { pageMode, shipmentCount },
  });
}

export function featureAnalyticsBulkUploadClick(pageMode: string, isReturningUser: boolean) {
  trackEvent({
    element: 'bulk-upload',
    action: 'click',
    data: { pageMode, isReturningUser },
  });
}

export function featureAnalyticsBulkUploadModalCloseClick(pageMode: string, isReturningUser?: boolean) {
  trackEvent({
    element: 'bulk-upload-modal-close',
    action: 'click',
    data: { pageMode, isReturningUser },
  });
}

export function featureAnalyticsBulkUploadNextClick(pageMode: string, isReturningUser?: boolean) {
  trackEvent({
    element: 'bulk-upload-next',
    action: 'click',
    data: { pageMode, isReturningUser },
  });
}

/**
 * My live boards events
 */

export function featureAnalyticsCreateNewLiveBoardClick(pageMode: string, isReturningUser: boolean) {
  trackEvent({
    element: 'create-new-live-board',
    action: 'click',
    data: { pageMode, isReturningUser },
  });
}

export function featureAnalyticsBoardNameClick(pageMode: string, boardMode: string, isReturningUser: boolean) {
  trackEvent({
    element: 'board-name',
    action: 'click',
    data: { pageMode, boardMode, isReturningUser },
  });
}

export function featureAnalyticsViewETAClick(pageMode: string, boardMode: string, isReturningUser: boolean) {
  trackEvent({
    element: 'view-eta',
    action: 'click',
    data: { pageMode, boardMode, isReturningUser },
  });
}

/**
 * Signup form events
 */

export function featureAnalyticsSignupFormView() {
  trackEvent({
    element: 'signup-form',
    action: 'view',
  });
}

export function featureAnalyticsSignupFormSubmitted() {
  trackEvent({
    pathOverride: 'free-tracking',
    element: 'signup-form',
    action: 'submit',
  });
}
