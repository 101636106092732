import { InMemoryStorageProvider, UnleashClient } from 'unleash-proxy-client';

export const FEATURE_FLAG_CLIENT_QUERY_KEY = 'featureFlagClient';

export type FeatureFlagClientType = typeof UnleashClient.prototype;

export const featureFlagApiPath = `/v1/feature-flags`;

export const featureFlagGatewayEndpoint = `${GATEWAY_SERVICE_HOST}${featureFlagApiPath}`;

export async function getNewFeatureFlagClient(beaconAuthToken: string | undefined = '') {
  return new UnleashClient({
    url: featureFlagGatewayEndpoint,
    clientKey: `Bearer ${beaconAuthToken}`,
    appName: 'beacon-webapp',
    environment: ENVIRONMENT,
    /**
     * Using InMemoryStorageProvider to avoid using LocalStorageProvider
     * Localstorage is shared across tabs and can cause issues with multiple tabs having different feature flags
     * This can only happen for CX team members who have multiple tabs open with different user accounts
     */
    storageProvider: new InMemoryStorageProvider(),
  });
}
