import { createContext } from 'react';
import { Customer } from '@beacon-devops/customer-service-client';
import { UserModel } from '@queries/currentUser/useCurrentUser';
import { UserClaims } from '../auth/claims/userClaims';
import { UserPermissions } from '../auth/permissions/userPermissions';

export interface CurrentUserContextType {
  currentUser?: UserModel;
  permissions?: UserPermissions;
  /**
   * Customer Permissions are the permissions that are specific to the customer and should only be used as fallback
   *  permissions when the user does not the permissions (e.g.: Board user)
   */
  customerPermissions?: UserPermissions;
  limits?: UserClaims['limits'];
  boardAccess?: UserClaims['boardAccess'];
  currentUserCustomer?: Customer;
}

export const CurrentUserContext = createContext<CurrentUserContextType>({
  currentUser: undefined,
  permissions: undefined,
  customerPermissions: undefined,
  currentUserCustomer: undefined,
  limits: undefined,
  boardAccess: undefined,
});
