import { useAvailableFreeTrackingCarriersApi } from '@services/LiveBoardsService';

interface Args {
  enabled?: boolean;
}

export function useCarrierListSelector({ enabled }: Args = { enabled: true }) {
  return useAvailableFreeTrackingCarriersApi({
    enabled,
    select: (data) =>
      data.availableFreeTrackingCarriers.map((carrier) => ({
        label: carrier.displayName || '',
        value: carrier.iataCode || carrier.nmftaCode || '',
      })),
  });
}
