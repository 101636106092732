import { Box } from '@beacon-devops/components';
import styled from 'styled-components';
import { BorderProps, MinWidthProps, SpaceProps, space } from 'styled-system';

type FlexContainerProps = {
  gap: string;
};

export const FlexContainer = styled(Box)<FlexContainerProps & SpaceProps & MinWidthProps & BorderProps>`
  display: flex;
  gap: ${(props) => (props.gap ? props.gap : '8px')};
  ${space}
`;

export const FlexColumnContainer = styled(Box)<FlexContainerProps & SpaceProps & MinWidthProps & BorderProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? props.gap : '8px')};
  ${space}
`;
