import { getGraphqlClient } from '@features/api';
import { graphql } from '../../generated/gql';
import { PagedPurchaseOrders, SearchSortType } from '../../generated/graphql';
import { endpoint } from '../endpoint';

const querySearchDrawerPurchaseOrders = graphql(/* GraphQL */ `
  query searchDrawerPurchaseOrders($customerId: ID!, $sort: [SearchSortCriteria!]) {
    searchPurchaseOrdersByCustomerId(
      page: 0
      size: 1000
      customerId: $customerId
      criteria: []
      sort: $sort
      macros: []
    ) {
      totalRecords
      pos {
        id
        poNumber
      }
    }
  }
`);

export const searchDrawerPurchaseOrders = async ({
  customerId,
}: {
  customerId: string;
}): Promise<PagedPurchaseOrders> => {
  const client = await getGraphqlClient(endpoint);
  const data = await client.request(querySearchDrawerPurchaseOrders, {
    customerId,
    sort: [
      {
        field: 'slimPurchaseOrders.createdOn',
        order_by: SearchSortType.Asc,
      },
    ],
  });

  return data.searchPurchaseOrdersByCustomerId as PagedPurchaseOrders;
};
