import { useState } from 'react';
import { Popover, Box, Icon, Text } from '@beacon-devops/components';
import { DropDownOptionBox } from './styles';

interface CompactDropdownPosition {
  left: number;
  top: number;
}

interface CompactDropdownOption {
  label: string;
  value: string;
}

interface CompactDropdownProps {
  options: CompactDropdownOption[];
  defaultValue: string;
  positionOverride?: ({ left, top }: CompactDropdownPosition) => CompactDropdownPosition;
  popoverId: string;
  onChange: (option: CompactDropdownOption) => void;
  isDisabled?: boolean;
}

interface CompactDropdownOptionProps {
  option: CompactDropdownOption;
  selected: boolean;
  onOptionSelect: (option: CompactDropdownOption) => void;
}

function CompactDropdownOption({ option, selected, onOptionSelect }: CompactDropdownOptionProps) {
  return (
    <DropDownOptionBox
      minWidth="200px"
      py={4}
      display="flex"
      pl={5}
      alignItems="center"
      onClick={() => onOptionSelect(option)}
    >
      <Icon name="controls-check" color={selected ? 'infoShades.1' : 'transparent'} size="1rem" mr={3} />
      <Text fontSize={2}>{option.label}</Text>
    </DropDownOptionBox>
  );
}

export function CompactDropdown({
  options,
  defaultValue,
  positionOverride,
  popoverId,
  onChange,
  isDisabled,
}: CompactDropdownProps) {
  const providedOption = options.find((option) => option.value === defaultValue);
  const [selectedOption, setSelectedOption] = useState(providedOption);

  return (
    <>
      <Box data-tip data-for={popoverId} display="inline-block">
        <Box display="flex" alignItems="center">
          <Text fontFamily="medium" fontSize={2} mr={2} data-testid={`${popoverId}-selected`}>
            {selectedOption?.label}
          </Text>
          {!isDisabled && <Icon name="chevron-down" color="secondaryShades.1" size="small" />}
        </Box>
      </Box>
      {!isDisabled && (
        <Popover id={popoverId} clickable triggerEvent="click" overridePosition={positionOverride}>
          <Box borderRadius={4}>
            {options.map((option) => (
              <CompactDropdownOption
                option={option}
                key={option.value}
                selected={option.value === selectedOption?.value}
                onOptionSelect={() => {
                  setSelectedOption(option);
                  onChange(option);
                }}
              />
            ))}
          </Box>
        </Popover>
      )}
    </>
  );
}
