import { UserPermissions } from '../userPermissions';

// Default mock provides least privileges so engineers can write tests by adding permissions covering permission specific cases
export const userPermissionsMock: UserPermissions = {
  canReadPO: false,
  canReadShipmentTracking: false,
  canRequestShipmentTracking: false,
  canReadDaysOnQuay: false,
  canReadLabels: false,
  canWriteLabels: false,
  canViewTrackingHome: false,
  canReadHistoricalWeatherData: false,
  canReadHistoricalShipments: false,
  canWriteToWatchlist: false,
  canReadWatchlist: false,
  canViewTableView: false,
  canExportCsv: false,
  canReadAddressBook: false,
  canWriteAddressBook: false,
  canReadHaulageTime: false,
  canWriteHaulageTime: false,
  canReadDocuments: false,
  canReadDocumentCount: false,
  canReadDaysOffQuay: false,
  canReadFreightRates: false,
  canReadOceanPointToPointSchedules: false,
  canReadCustomFields: false,
  canWriteCustomFields: false,
  canRequestOceanShipmentTrackingWithoutCarrier: false,
  canReadOceanRoutesReport: false,
  canReadAirVolumeReport: false,
  canReadOceanVolumeReport: false,
  canReadOceanTransitTimeReport: false,
  canReadAirTransitTimeReport: false,
  canReadOceanEmissionsReport: false,
  canReadOceanVesselGeolocationData: false,
  canReadRoadVolumeReport: false,
  canReadRoadTransitTimeReport: false,
  canDeleteShipmentTracking: false,
  canReadBoard: false,
  canViewBoardsOnly: false,
  canWriteCustomer: false,
  canWriteOrderBoards: false,
};
