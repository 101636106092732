import { useEffect } from 'react';
import { Text, Box, Image, TextButton, PlatformGlobalStyles, theme } from '@beacon-devops/components';
import { captureException } from '@sentry/react';
import { useRouteError } from 'react-router-dom';
import { AppPageLayout } from '@components/layout/AppPageLayout';
import { trackError } from '@utils/common/helpers/trackEvent';
import { ThemeProvider } from 'styled-components';

const ErrorPage = () => {
  let error = useRouteError();
  console.error(error);
  captureException(error);

  useEffect(() => {
    trackError('Something went wrong here');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <PlatformGlobalStyles theme={theme} />
      <AppPageLayout hidePageHeader>
        <Box py={[5, 5, 6]} height="100%">
          <Box maxWidth="1400px" mx="auto" px={4}>
            <Box display="flex" flexWrap="wrap" alignItems="center" m={-3}>
              <Box width={[1, 1, 1 / 2]} p={3}>
                <Text as="h1" fontSize={6} fontFamily="semibold">
                  Something went wrong here
                </Text>
                <Text>
                  If the problem continues to persist, please{' '}
                  <TextButton
                    as="a"
                    href="mailto:support@beacon.co.uk"
                    title="Email Beacon support team"
                    fontSize={3}
                    fontFamily="regular"
                  >
                    contact support
                  </TextButton>
                </Text>
              </Box>
              <Box width={[1, 1, 1 / 2]} p={3}>
                <Image src="https://cdn.beacon.co.uk/raw/images/images/under-maintenance.png" alt="404" maxWidth="100%" />
              </Box>
            </Box>
          </Box>
        </Box>
      </AppPageLayout>
    </ThemeProvider>
  );
};

export default ErrorPage;
