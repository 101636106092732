import { createContext } from 'react';
import { IsFeatureEnabled } from '../types';
import { getIsFeatureEnabled } from '../utils';

interface FeatureFlagContextInterface {
  isFeatureEnabled: IsFeatureEnabled;
  isLoading: boolean;
}

export const FeatureFlagContext = createContext<FeatureFlagContextInterface>({
  isFeatureEnabled: getIsFeatureEnabled(),
  isLoading: false,
});
