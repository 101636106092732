import { useEffect, useState } from 'react';
import { Box, MarketingGlobalStyles, marketingTheme } from '@beacon-devops/components';
import { ThemeProvider } from 'styled-components';
import './hubspotMarketingStyles.css';
import { useBreakpoint } from 'use-breakpoint';
import { BoxedLoader } from '@components/loaders';
import { BREAKPOINTS } from '@constants/mediaQueries';
import { HUBSPOT_SIGNUP_FORM_URL, HUBSPOT_TARGET_ELEMENT_ID } from './constants';
import { setBoardIdInHubspotForm, createHubspotForm } from './utils';
import {
  featureAnalyticsSignupFormSubmitted,
  featureAnalyticsSignupFormView,
} from '../../analytics/freeTrackingPageAnalytics';

interface Props {
  boardId: string;
}

export function HubSpotSignupForm({ boardId }: Props) {
  const targetId = HUBSPOT_TARGET_ELEMENT_ID;
  const [isLoading, setIsLoading] = useState(true);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  function onHubspotFormReady() {
    setIsLoading(false);
    setBoardIdInHubspotForm(boardId);
  }

  function onHubspotFormSubmitted() {
    featureAnalyticsSignupFormSubmitted();
  }

  useEffect(() => {
    featureAnalyticsSignupFormView();
  }, [boardId]);

  useEffect(() => {
    if (!window.hbspt) {
      const script = document.createElement('script');
      script.src = HUBSPOT_SIGNUP_FORM_URL;
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        createHubspotForm(targetId, onHubspotFormReady, onHubspotFormSubmitted);
      });
    } else {
      createHubspotForm(targetId, onHubspotFormReady, onHubspotFormSubmitted);
    }
  }, []);

  return (
    <ThemeProvider theme={marketingTheme}>
      <MarketingGlobalStyles theme={marketingTheme} />
      <Box width={breakpoint === 'phone' ? '100%' : 630} id={targetId} />
      {isLoading && (
        <Box position="absolute" top={0} right={0} left={0} bottom={0}>
          <BoxedLoader />
        </Box>
      )}
    </ThemeProvider>
  );
}
