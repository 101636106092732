/**
 *
 * @description This method calculates the interval for refetching the board cargos
 * based on the refetch count and if the board is a free tracking board
 * If the board is free tracking board we need to refetch the cargos more frequently
 * so that the user can see the latest data as soon as possible
 *
 * @param refetchCount
 * @param isFreeTrackingBoard
 * @returns
 */

export function boardCargoRefetchInterval(
  refetchCount: number,
  allCargosProcessing: boolean,
  isFreeTrackingBoard: boolean | undefined,
) {
  let interval = 60000;

  if (isFreeTrackingBoard && allCargosProcessing) {
    interval = 3000;
    if (refetchCount > 10 && refetchCount <= 20) {
      interval = 5000;
    } else if (refetchCount > 20 && refetchCount <= 30) {
      interval = 10000;
    } else if (refetchCount > 30) {
      interval = 60000;
    }
  }

  return interval;
}
