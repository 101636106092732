import { Box, Button } from '@beacon-devops/components';
import styled from 'styled-components';
import { phone } from '@constants/mediaQueries';

export const StartTrackingButton = styled(Button)`
  position: absolute;
  bottom: -27px;

  ${phone} {
    position: static;
    bottom: 0;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.space[5]};
  }
`;

export const AdditionShipmentCta = styled(Box)`
  ${phone} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const ShipmentsLeftText = styled(Box)`
  width: 50%;
  float: right;
  text-align: right;

  ${phone} {
    text-align: center;
  }
`;
