import { PropsWithChildren } from 'react';
import { PrivacySetting } from '@features/boards/types/privacySetting';
import { BoardType } from '@services/LiveBoardsService/generated/graphql';
import { Mode } from '@services/ShipmentDataGatewayService';
import { BoardByExternalBoardId } from '../../../../selectors/useBoardByExternalBoardIdSelector';
import { LiveBoardContext } from '../LiveBoardContext';

// eslint-disable-next-line react-refresh/only-export-components
export const boardDataFixture: NonNullable<BoardByExternalBoardId> = {
  externalBoardId: '5f478c614548',
  boardId: 'bcn-5f478c614548',
  boardName: 'New Boards',
  boardType: BoardType.Cargo,
  customerName: 'Customer 1',
  customerId: 'customerId',
  createdByName: 'Some User',
  createdAt: '2017-07-21T17:32:28Z',
  isPublic: true,
  privacySetting: PrivacySetting.Public,
  boardPath: '/live-board/New%20Boards/Customer%201/5f478c614548',
  mode: Mode.Ocean,
  appliedSearchFilters: [],
  appliedMacros: [],
  boardUrl: 'mockBoardUrl',
  isFreeTrackingBoard: false,
  cargoCount: 0,
  orderCount: 0,
};

// eslint-disable-next-line react-refresh/only-export-components
export const orderBoardDataFixture: NonNullable<BoardByExternalBoardId> = {
  externalBoardId: '5f478c614548',
  boardId: 'bcn-5f478c614548',
  boardName: 'New Boards',
  boardType: BoardType.Order,
  customerName: 'Customer 1',
  customerId: 'customerId',
  createdByName: 'Some User',
  createdAt: '2017-07-21T17:32:28Z',
  isPublic: true,
  privacySetting: PrivacySetting.Public,
  boardPath: '/live-board/New%20Boards/Customer%201/5f478c614548',
  mode: Mode.Ocean,
  appliedSearchFilters: [],
  appliedMacros: [],
  boardUrl: 'mockBoardUrl',
  isFreeTrackingBoard: false,
  cargoCount: 10,
  orderCount: 10,
};

interface Props {
  boardData?: BoardByExternalBoardId;
  loggedInAndCurrentCustomerIdIsBoardCustomerId?: boolean;
  boardHasShipments?: boolean;
}

export function MockLiveBoardContextProvider({
  children,
  boardData = boardDataFixture,
  ...rest
}: PropsWithChildren<Props | undefined>) {
  return (
    <LiveBoardContext
      boardData={boardData}
      loggedInAndCurrentCustomerIdIsBoardCustomerId={rest.boardHasShipments ?? false}
      boardHasShipments={rest.boardHasShipments ?? true}
      entityView="shipment"
      setEntityView={() => undefined}
    >
      {children}
    </LiveBoardContext>
  );
}

export function MockOrderLiveBoardContextProvider({
  children,
  boardData = orderBoardDataFixture,
  ...rest
}: PropsWithChildren<Props | undefined>) {
  return (
    <LiveBoardContext
      boardData={boardData}
      loggedInAndCurrentCustomerIdIsBoardCustomerId
      boardHasShipments={rest.boardHasShipments ?? true}
      entityView="order"
      setEntityView={() => undefined}
    >
      {children}
    </LiveBoardContext>
  );
}
