import { graphql } from '../../../../generated/gql';

export const updateCustomFieldNameMutation = graphql(/* GraphQL */ `
  mutation updateCustomFieldName($id: ID!, $name: String!) {
    updateCustomFieldName(id: $id, name: $name) {
      id
      name
    }
  }
`);
