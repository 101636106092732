import { FeatureFlagClientType } from '@services/FeatureFlagsService';
import featureFlags from './featureFlags.json';
import { IsFeatureEnabled } from './types';

function getFeatureFlagNameWithEnvironment(featureFlagName: string) {
  return `${ENVIRONMENT}-frontend-${featureFlagName}`;
}

export function getIsFeatureEnabled(featureFlagClient?: FeatureFlagClientType) {
  return Object.entries(featureFlags).reduce<IsFeatureEnabled>((acc, [featureFormattedName, featureFlagName]) => {
    acc[featureFormattedName] = featureFlagClient?.isEnabled
      ? featureFlagClient.isEnabled(getFeatureFlagNameWithEnvironment(featureFlagName))
      : false;
    return acc;
  }, {} as IsFeatureEnabled);
}
